"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateNextAttemptToBeMadeAt = void 0;
const date_1 = require("../../utilities/date/date");
function calculateNextAttemptToBeMadeAt(timeBetweenAttempts, date) {
    const nextDate = (0, date_1.addMillisecondsToDate)(date, timeBetweenAttempts);
    nextDate.setUTCHours(0, 0, 0, 0);
    return nextDate;
}
exports.calculateNextAttemptToBeMadeAt = calculateNextAttemptToBeMadeAt;
