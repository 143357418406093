"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.currentMappedEnvVariables = void 0;
const common_1 = require("@augment-vir/common");
const env_1 = require("./env");
const env_category_1 = require("./env-category");
const devOriginPort = 9000;
const devGraphQlLambdaPort = 4030;
const urlOrigin = {
    [env_1.EnvEnum.BetaProd]: 'https://beta.app.jillsoffice.com',
    [env_1.EnvEnum.Prod]: 'https://app.jillsoffice.com',
    [env_1.EnvEnum.BetaStaging]: 'https://beta.app.staging.jillsoffice.com',
    [env_1.EnvEnum.Staging]: 'https://app.staging.jillsoffice.com',
    [env_1.EnvEnum.Dev]: `http://localhost:${devOriginPort}`,
    [env_1.EnvEnum.DevAndroid]: `http://10.0.2.2:${devOriginPort}`,
};
const graphQlPlaygroundPath = 'graphql-playground';
const envVariableMappings = (0, common_1.wrapNarrowTypeWithTypeCheck)()({
    urlOrigin,
    graphqlPlaygroundWorkspace: {
        ...(0, common_1.mapObjectValues)(urlOrigin, (key, origin) => origin + '/'),
        [env_1.EnvEnum.Dev]: `${urlOrigin[env_1.EnvEnum.Dev]}/${graphQlPlaygroundPath}`,
        [env_1.EnvEnum.DevAndroid]: `${urlOrigin[env_1.EnvEnum.DevAndroid]}/${graphQlPlaygroundPath}`,
    },
    graphqlPlaygroundEndpoint: {
        [env_1.EnvEnum.Prod]: `${urlOrigin[env_1.EnvEnum.Prod]}/${graphQlPlaygroundPath}`,
        [env_1.EnvEnum.Staging]: `${urlOrigin[env_1.EnvEnum.Staging]}/${graphQlPlaygroundPath}`,
        [env_1.EnvEnum.Dev]: `http://localhost:${devOriginPort}/${graphQlPlaygroundPath}`,
        [env_1.EnvEnum.DevAndroid]: `http://10.0.2.2:${devOriginPort}/${graphQlPlaygroundPath}`,
    },
    graphqlLambdaEndpoint: {
        [env_1.EnvEnum.Prod]: 'https://graphql.lambda.new.jillsoffice.com',
        [env_1.EnvEnum.Staging]: 'https://graphql.lambda.staging.jillsoffice.com',
        [env_1.EnvEnum.Dev]: `http://localhost:${devGraphQlLambdaPort}`,
        [env_1.EnvEnum.DevAndroid]: `http://10.0.2.2:${devGraphQlLambdaPort}`,
    },
    /**
     * Only use for requests that don't fit into the light or heavy container conventions. See
     * https://jillsoffice.atlassian.net/wiki/spaces/JW/pages/251265025/GraphQL+Container+Usage+Conventions.
     */
    graphqlMediumContainerEndpoint: {
        [env_1.EnvEnum.Prod]: 'https://medium.graphql.container.new.jillsoffice.com',
        [env_1.EnvEnum.Staging]: 'https://medium.graphql.container.staging.jillsoffice.com',
        [env_1.EnvEnum.Dev]: `http://localhost:${devGraphQlLambdaPort}`,
        [env_1.EnvEnum.DevAndroid]: `http://10.0.2.2:${devGraphQlLambdaPort}`,
    },
    /**
     * Only use for heavy duty / long running requests and reports. See
     * https://jillsoffice.atlassian.net/wiki/spaces/JW/pages/251265025/GraphQL+Container+Usage+Conventions.
     */
    graphqlHeavyContainerEndpoint: {
        [env_1.EnvEnum.Prod]: 'https://heavy.graphql.container.new.jillsoffice.com',
        [env_1.EnvEnum.Staging]: 'https://heavy.graphql.container.staging.jillsoffice.com',
        [env_1.EnvEnum.Dev]: `http://localhost:${devGraphQlLambdaPort}`,
        [env_1.EnvEnum.DevAndroid]: `http://10.0.2.2:${devGraphQlLambdaPort}`,
    },
    /**
     * Only use requests that are absolutely required for calls, the call screen, and chat to
     * function. See
     * https://jillsoffice.atlassian.net/wiki/spaces/JW/pages/251265025/GraphQL+Container+Usage+Conventions.
     */
    graphqlLightContainerEndpoint: {
        [env_1.EnvEnum.Prod]: `https://light.graphql.container.new.jillsoffice.com`,
        [env_1.EnvEnum.Staging]: `https://light.graphql.container.staging.jillsoffice.com`,
        [env_1.EnvEnum.Dev]: `http://localhost:${devGraphQlLambdaPort}`,
        [env_1.EnvEnum.DevAndroid]: `http://10.0.2.2:${devGraphQlLambdaPort}`,
    },
    routerServerEndpoint: {
        [env_1.EnvEnum.Prod]: `https://router.container.new.jillsoffice.com`,
        [env_1.EnvEnum.Staging]: `https://router.container.staging.jillsoffice.com`,
        [env_1.EnvEnum.Dev]: `http://localhost:4060`,
        [env_1.EnvEnum.DevAndroid]: `http://10.0.2.2:4060`,
    },
    graphqlWebSocketEndpoint: {
        [env_1.EnvEnum.Prod]: `wss://graphql.new.jillsoffice.com/graphql`,
        [env_1.EnvEnum.Staging]: `wss://graphql.staging.jillsoffice.com/graphql`,
        [env_1.EnvEnum.Dev]: `ws://localhost:${devGraphQlLambdaPort}/graphql`,
        [env_1.EnvEnum.DevAndroid]: `ws://10.0.2.2:${devGraphQlLambdaPort}/graphql`,
    },
    chatBotSourceFile: {
        [env_1.EnvEnum.Prod]: 'https://chatbot.jillsoffice.com/index.bundle.js',
        [env_1.EnvEnum.Staging]: 'https://chatbot.staging.jillsoffice.com/index.bundle.js',
        [env_1.EnvEnum.Dev]: 'index.bundle.js',
    },
});
const shouldUseStagingBackend = globalThis.localStorage.getItem('BACKEND');
function getMappedValueByEnv(mappingEntry, envKey) {
    const finalEnvKeyToUse = shouldUseStagingBackend && (0, env_1.isEnv)(env_1.EnvEnum.Dev) ? env_1.EnvEnum.Staging : envKey;
    const envCategory = (0, env_category_1.getEnvCategory)(finalEnvKeyToUse);
    const envValue = mappingEntry[finalEnvKeyToUse];
    const envCategoryValue = mappingEntry[envCategory];
    return envValue ?? envCategoryValue;
}
function mapEnvVariables(mapping, envKey = env_1.currentEnv) {
    return (0, common_1.mapObjectValues)(mapping, (key, mappingEntry) => {
        return getMappedValueByEnv(mappingEntry, envKey);
    });
}
exports.currentMappedEnvVariables = mapEnvVariables(envVariableMappings);
// /**
//  * Use the following code to produce a full mapping output so it can be compared when changes are
//  * made to ensure refactors don't change current values.
//  */
// const completeMapping = mapObjectValues(envVariableMappings, (key, mappingEntry) => {
//     const allValues = Object.fromEntries(
//         [
//             EnvEnum.BetaProd,
//             EnvEnum.Prod,
//             EnvEnum.BetaStaging,
//             EnvEnum.Staging,
//             EnvEnum.Dev,
//             EnvEnum.DevAndroid,
//         ].map(envValue => {
//             return [
//                 envValue,
//                 getMappedValueByEnv(mappingEntry, envValue),
//             ];
//         }),
//     );
//     return allValues;
// });
//
// console.info(JSON.stringify(completeMapping, null, 4));
