"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.doesObjectBContainEverythingInObjectAExcludingFormErrors = exports.areObjectsEqualMinusFormErrors = void 0;
const dequal_1 = require("dequal");
function areObjectsEqualMinusFormErrors(firstObj, secondObj) {
    return (doesObjectBContainEverythingInObjectAExcludingFormErrors(firstObj, secondObj) &&
        doesObjectBContainEverythingInObjectAExcludingFormErrors(secondObj, firstObj));
}
exports.areObjectsEqualMinusFormErrors = areObjectsEqualMinusFormErrors;
function doesObjectBContainEverythingInObjectAExcludingFormErrors(objA, objB) {
    const keys = Object.keys(objA);
    for (const key of keys) {
        if (key.toLowerCase() === 'formerrors')
            continue;
        else if (Array.isArray(objA[key])) {
            if (!Array.isArray(objB[key]))
                return false;
            if (!(0, dequal_1.dequal)(objA[key], objB[key]))
                return false;
        }
        else if (typeof objA[key] === 'object') {
            if (!objB[key] || typeof objB[key] !== 'object')
                return false;
            if (!doesObjectBContainEverythingInObjectAExcludingFormErrors(objA[key], objB[key]))
                return false;
        }
        else {
            if (objA[key] !== objB[key])
                return false;
        }
    }
    return true;
}
exports.doesObjectBContainEverythingInObjectAExcludingFormErrors = doesObjectBContainEverythingInObjectAExcludingFormErrors;
