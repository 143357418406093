"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldMarkOutboundQueueItemAsCompleted = void 0;
function shouldMarkOutboundQueueItemAsCompleted(outboundQueueItem) {
    if (!outboundQueueItem.outbound_attempts ||
        !outboundQueueItem.num_attempts_to_make ||
        outboundQueueItem.status === 'COMPLETED' ||
        outboundQueueItem.status === 'MANUALLY_CLOSED') {
        return false;
    }
    return outboundQueueItem.outbound_attempts?.length >= outboundQueueItem.num_attempts_to_make;
}
exports.shouldMarkOutboundQueueItemAsCompleted = shouldMarkOutboundQueueItemAsCompleted;
