"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchMostRecentUserStatusEventForUser = exports.createUserStatusEvent = void 0;
const graphql_1 = require("../../services/graphql");
const store_1 = require("../../services/store");
const utilities_1 = require("../../services/utilities");
const mapped_env_variables_1 = require("../environment/mapped-env-variables");
async function createUserStatusEvent(params) {
    try {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($input: CreateUser_status_eventsInput!) {
                    createUser_status_events(input: $input) {
                        id
                    }
                }
            `, {
            input: {
                action_item: {
                    connect: {
                        id: params.actionItemId,
                    },
                },
                user: {
                    connect: {
                        id: params.userId,
                    },
                },
                type: params.eventType,
                timestamp: params.timestamp?.toISOString() ?? new Date().toISOString(),
                company: {
                    connect: {
                        id: params.companyId ?? null,
                    },
                },
            },
        });
    }
    catch (error) {
        (0, utilities_1.handleError)(`createNewStatusEvent error ${error}, userId: ${params.userId}, eventType: ${params.eventType}, user: ${store_1.GlobalStore.authenticatedUser}`);
    }
}
exports.createUserStatusEvent = createUserStatusEvent;
async function fetchMostRecentUserStatusEventForUser(userId) {
    const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query ($userId: Int!) {
                findUser_status_events(
                    filter: {user_id: {eq: $userId}}
                    page: {limit: 1}
                    orderBy: {field: "timestamp", order: DESC}
                ) {
                    items {
                        id
                        timestamp
                        type
                    }
                }
            }
        `, {
        userId,
    });
    const mostRecentUserStatusEvent = gqlResult.data.findUser_status_events.items[0];
    return mostRecentUserStatusEvent ?? null;
}
exports.fetchMostRecentUserStatusEventForUser = fetchMostRecentUserStatusEventForUser;
