"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAuthKeysFromLocalStorage = void 0;
const constants_1 = require("./constants");
function getAuthKeysFromLocalStorage() {
    return {
        clientPublicKey: window.localStorage.getItem(constants_1.clientPublicKeyName) || undefined,
        clientPrivateKey: window.localStorage.getItem(constants_1.clientPrivateKeyName) || undefined,
        csrfToken: window.localStorage.getItem(constants_1.csrfTokenName) || undefined,
    };
}
exports.getAuthKeysFromLocalStorage = getAuthKeysFromLocalStorage;
