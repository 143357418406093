"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createRingtoneSelectionCards = exports.createUserSettings = exports.updateUserSettings = exports.upsertUserSettings = exports.fetchUserSettings = exports.fetchAndSetUserProfileSettings = exports.updateSettingsButtonClick = void 0;
const element_vir_1 = require("element-vir");
const graphql_1 = require("../../../services/graphql");
const utilities_1 = require("../../../services/utilities");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
const handle_gql_result_failure_1 = require("../../../utilities/error-handling/handle-gql-result-failure");
const convert_snake_to_title_case_1 = require("../../../utilities/strings/casings.ts/convert-snake-to-title-case");
const ring_tone_options_1 = require("./ring-tone-options");
const jo_audio_button_element_1 = require("../../elements/jo-audio-button/jo-audio-button.element");
async function updateSettingsButtonClick(authenticatedUserId, selectedRingtoneUrl, userSettingsId, updateState) {
    const id = await upsertUserSettings(authenticatedUserId, selectedRingtoneUrl, userSettingsId);
    if (id !== undefined) {
        await (0, utilities_1.joAlert)('Success', 'Settings successfully updated. Your page will now refresh to apply those settings.');
        window.location.reload();
    }
}
exports.updateSettingsButtonClick = updateSettingsButtonClick;
async function fetchAndSetUserProfileSettings(userId, updateState) {
    if (!userId)
        throw Error('Cannot get profile settings, user is undefined');
    const userSettings = await fetchUserSettings(userId);
    if (userSettings) {
        const ringTone = userSettings.selected_ring_tone ?? undefined;
        const id = userSettings.id;
        updateState({
            userSettingsId: id,
            selectedRingtoneUrl: ringTone,
        });
    }
    return;
}
exports.fetchAndSetUserProfileSettings = fetchAndSetUserProfileSettings;
async function fetchUserSettings(userId) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query ($userId: Int!) {
                getUsers(id: $userId) {
                    id
                    user_settings {
                        id
                        selected_ring_tone
                    }
                }
            }
        `, {
        userId,
    });
    if (gqlResult.succeeded === false) {
        (0, handle_gql_result_failure_1.handleGqlResultFailure)(gqlResult);
        return;
    }
    return gqlResult.value.data.getUsers.user_settings[0];
}
exports.fetchUserSettings = fetchUserSettings;
async function upsertUserSettings(authenticatedUserId, ringTone, userSettingsId) {
    if (!ringTone || !authenticatedUserId) {
        (0, utilities_1.joAlert)('Error', 'Not able to save settings');
        throw new Error(`One or more parameters is undefined: ${authenticatedUserId}, ${ringTone}`);
    }
    if (userSettingsId) {
        return await updateUserSettings(userSettingsId, ringTone);
    }
    else {
        return await createUserSettings(ringTone, authenticatedUserId);
    }
}
exports.upsertUserSettings = upsertUserSettings;
async function updateUserSettings(userSettingsId, ringTone) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($userSettingsId: Int!, $ringTone: String) {
                updateUser_settings(input: {id: $userSettingsId, selected_ring_tone: $ringTone}) {
                    id
                }
            }
        `, {
        userSettingsId,
        ringTone,
    });
    if (gqlResult.succeeded === false) {
        (0, handle_gql_result_failure_1.handleGqlResultFailure)(gqlResult);
        return undefined;
    }
    return gqlResult.value.data.updateUser_settings.id;
}
exports.updateUserSettings = updateUserSettings;
async function createUserSettings(ringTone, authenticatedUserId) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($ringTone: String, $authenticatedUserId: Int) {
                createUser_settings(
                    input: {
                        selected_ring_tone: $ringTone
                        user: {connect: {id: $authenticatedUserId}}
                    }
                ) {
                    id
                }
            }
        `, {
        ringTone,
        authenticatedUserId,
    });
    if (gqlResult.succeeded === false) {
        (0, handle_gql_result_failure_1.handleGqlResultFailure)(gqlResult);
        return undefined;
    }
    return gqlResult.value.data.createUser_settings.id;
}
exports.createUserSettings = createUserSettings;
function createRingtoneSelectionCards(updateState, ringtoneUrlForCard, selectedRingtoneUrl, ringtoneName, currentlyPlayingAudio) {
    return (0, element_vir_1.html) `
        <div
            ${(0, element_vir_1.listen)('click', () => {
        updateState({
            selectedRingtoneUrl: ringtoneUrlForCard,
        });
    })}
            class=${(0, element_vir_1.classMap)({
        'jo-global--card': true,
        'ringtone-item': true,
        selected: ringtoneUrlForCard === selectedRingtoneUrl,
    })}
        >
            ${displayCheckMarkIfCardIsSelected(ringtoneUrlForCard, selectedRingtoneUrl)}

            <span>${(0, convert_snake_to_title_case_1.convertSnakeToTitleCase)(ringtoneName)}</span>

            ${(0, element_vir_1.renderIf)(ringtoneName !== ring_tone_options_1.ringtones.default.Default, 
    //the default one is Twilios default so we don't have the audio for it and thus can't play it.
    (0, element_vir_1.html) `
                    <${jo_audio_button_element_1.JoAudioButton}
                        ${(0, element_vir_1.assign)(jo_audio_button_element_1.JoAudioButton, {
        currentlyPlayingAudio,
        urlOfNewAudioToPlay: ringtoneUrlForCard,
    })}
                        ${(0, element_vir_1.listen)(jo_audio_button_element_1.JoAudioButton.events.updateCurrentlyPlayingAudio, event => {
        updateState({
            currentlyPlayingAudio: event.detail,
        });
    })}
                    ></${jo_audio_button_element_1.JoAudioButton}>
                `)}
        </div>
    `;
}
exports.createRingtoneSelectionCards = createRingtoneSelectionCards;
function displayCheckMarkIfCardIsSelected(ringtoneUrlForCard, selectedRingtoneUrl) {
    return (0, element_vir_1.renderIf)(ringtoneUrlForCard === selectedRingtoneUrl, (0, element_vir_1.html) `
            <span class="material-icons check-mark">check</span>
        `);
}
