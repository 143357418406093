"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleError = exports.convertErrorDetailsToLogDetails = void 0;
const log_context_1 = require("../log-context");
const web_client_interface_1 = require("../../web-client-interface/web-client-interface");
function convertErrorDetailsToLogDetails(errorDetails) {
    return {
        severity: log_context_1.LogSeverityEnum.Error,
        ...errorDetails,
    };
}
exports.convertErrorDetailsToLogDetails = convertErrorDetailsToLogDetails;
/**
 * Record an error to our logging services without actually throwing it in the code so we don't
 * crash anything.
 */
function handleError(error, errorDetails) {
    const sentryClient = (0, web_client_interface_1.getWebClientInterface)().sentryClient;
    const scopeContext = (0, log_context_1.createLoggingContext)(convertErrorDetailsToLogDetails(errorDetails));
    const eventId = sentryClient.captureException(error, scopeContext);
    console.error('handled error:', error, errorDetails);
    return eventId;
}
exports.handleError = handleError;
