"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateOutboundQueueItemNextAttemptToBeMadeAt = void 0;
const graphql_1 = require("../../services/graphql");
const calculate_next_attempt_to_be_made_at_1 = require("./calculate-next-attempt-to-be-made-at");
const mapped_env_variables_1 = require("../environment/mapped-env-variables");
async function updateOutboundQueueItemNextAttemptToBeMadeAt(outboundQueueItem) {
    try {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($input: UpdateOutbound_queue_itemsInput!) {
                    updateOutbound_queue_items(input: $input) {
                        id
                    }
                }
            `, {
            input: generateInputToUpdateNextAttemptToBeMadeAt(outboundQueueItem),
        });
    }
    catch (error) {
        throw new Error(`updateNextAttemptToBeMadeAt error: ${error}`);
    }
}
exports.updateOutboundQueueItemNextAttemptToBeMadeAt = updateOutboundQueueItemNextAttemptToBeMadeAt;
function generateInputToUpdateNextAttemptToBeMadeAt(outboundQueueItem) {
    const now = new Date();
    return {
        id: outboundQueueItem.id,
        next_attempt_to_be_made_at: (0, calculate_next_attempt_to_be_made_at_1.calculateNextAttemptToBeMadeAt)(outboundQueueItem.time_between_attempts, now).toISOString(),
    };
}
