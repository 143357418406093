"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createFirebaseClient = void 0;
const firebaseClient = __importStar(require("firebase/analytics"));
const app_1 = require("firebase/app");
// this config was copy-pasted from console.firebase.google.com
const firebaseConfig = {
    apiKey: 'AIzaSyDK-5gYVPfPrjloA-5KiGmSGKdOLHRL4p4',
    authDomain: 'jills-office-1316.firebaseapp.com',
    databaseURL: 'https://jills-office-1316.firebaseio.com',
    projectId: 'jills-office-1316',
    storageBucket: 'jills-office-1316.appspot.com',
    messagingSenderId: '249986176103',
    appId: '1:249986176103:web:e47bbc1ae8e6956ad170c2',
    measurementId: 'G-8CPS5CXTJL',
};
function initFirebase() {
    try {
        // Initialize Firebase
        const app = (0, app_1.initializeApp)(firebaseConfig);
        // start up analytics
        firebaseClient.getAnalytics(app);
    }
    catch (error) {
        // prevent firebase init errors from crashing the app
        console.error(error);
    }
}
function createFirebaseClient() {
    initFirebase();
    return firebaseClient;
}
exports.createFirebaseClient = createFirebaseClient;
