"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendLog = void 0;
const common_1 = require("@augment-vir/common");
const log_context_1 = require("./log-context");
const web_client_interface_1 = require("../web-client-interface/web-client-interface");
exports.sendLog = (0, common_1.wrapNarrowTypeWithTypeCheck)()({
    [log_context_1.LogSeverityEnum.Debug]: wrapLogWithSeverity(log_context_1.LogSeverityEnum.Debug),
    [log_context_1.LogSeverityEnum.Info]: wrapLogWithSeverity(log_context_1.LogSeverityEnum.Info),
    [log_context_1.LogSeverityEnum.Warning]: wrapLogWithSeverity(log_context_1.LogSeverityEnum.Warning),
});
function wrapLogWithSeverity(severity) {
    return (info, infoDetails) => {
        return sendLogToSentry(info, {
            ...infoDetails,
            severity,
        });
    };
}
function sendLogToSentry(info, infoDetails) {
    const sentryClient = (0, web_client_interface_1.getWebClientInterface)().sentryClient;
    const scopeContext = (0, log_context_1.createLoggingContext)(infoDetails);
    let eventId;
    if ((0, common_1.isRuntimeTypeOf)(info, 'string')) {
        eventId = sentryClient.captureMessage(info, scopeContext);
    }
    else {
        eventId = sentryClient.captureEvent({
            ...info,
            ...scopeContext,
        });
    }
    return eventId;
}
