"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOConfirm = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const test_id_directive_1 = require("../directives/test-id.directive");
require("./jo-button.element");
const InitialState = {
    confirmTitle: 'NOT_SET',
    confirmMessage: 'NOT_SET',
};
class JOConfirm extends HTMLElement {
    constructor(confirmTitle, confirmMessage) {
        super();
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
        this.store.confirmTitle = confirmTitle;
        this.store.confirmMessage = confirmMessage;
    }
    buttonClicked(response) {
        if (response === true) {
            this.dispatchEvent(new CustomEvent('ok'));
        }
        else {
            this.dispatchEvent(new CustomEvent('cancel'));
        }
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-confirm--main-container {
                    font-family: sans-serif;
                }

                .jo-confirm--darken-screen {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.65);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: var(--z-index-2);
                }

                @media (max-width: 768px) {
                    .jo-confirm--darken-screen {
                        align-items: flex-start;
                    }
                }

                .jo-confirm--fade-in {
                    opacity: 0;
                    animation-name: jo-confirm--fade-in;
                    animation-duration: 0.125;
                    animation-fill-mode: forwards;
                }

                .jo-confirm--window {
                    width: 35%;
                    background: white;
                    border-radius: 5px;
                    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
                }

                @media (max-width: 1280px) {
                    .jo-confirm--window {
                        width: 50%;
                    }
                }

                @media (max-width: 768px) {
                    .jo-confirm--window {
                        margin-top: 40%;
                        width: 75%;
                    }
                }

                .jo-confirm--window-popup {
                    opacity: 0;
                    transform: scale(0.5);
                    animation-name: jo-confirm--window-popup;
                    animation-duration: 0.15s;
                    animation-timing-function: ease-out;
                    animation-fill-mode: forwards;
                }

                .jo-confirm--title-bar {
                    background: var(--jo-accent-background-light);
                    padding: 1.2em;
                    border-bottom: 1px solid var(--jo-accent-background-light);
                    box-sizing: border-box;
                    display: flex;
                    align-content: center;
                    justify-content: space-between;
                }

                .jo-confirm--title {
                    font-weight: bold;
                }

                .jo-confirm--close {
                    color: var(--jo-text-default);
                    transform: scale(1.5);
                    cursor: pointer;
                }

                .jo-confirm--close:hover {
                    color: black;
                    transform: scale(1.65);
                }

                .jo-confirm--message {
                    padding: 1.5em;
                    background: #fff;
                    border-bottom: 1px solid var(--jo-accent-background-light);
                    box-sizing: border-box;
                }

                .jo-confirm--buttons-row {
                    background: var(--jo-accent-background-light);
                    padding: 1em;
                    display: flex;
                    justify-content: flex-end;
                }

                .jo-confirm--button {
                    border-radius: 4px;
                    margin-left: 1.2em;
                    font-size: 16px;
                    padding: 8px 20px;
                    cursor: pointer;
                    border: none;
                    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.24);
                    outline: none;
                    box-sizing: border-box;
                }

                .jo-confirm--button:hover {
                    transform: scale(1.06);
                    box-shadow: 5px 5px 12px 0 rgba(0, 0, 0, 0.24);
                }

                .jo-confirm--button:focus {
                    box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.24);
                    transform: scale(1.02);
                }

                .jo-confirm--button-continue {
                    background: var(--jo-primary);
                    color: white;
                }

                .jo-confirm--button-continue:hover {
                    background: var(--jo-primary);
                }

                .jo-confirm--button-continue:focus {
                    background: var(--jo-secondary);
                }

                .jo-global--alert-button-cancel:hover {
                    background: rgb(0, 0, 0, 0.025);
                }

                .jo-global--alert-button-cancel:focus {
                    background: rgb(0, 0, 0, 0.05);
                }

                @keyframes jo-confirm--fade-in {
                    to {
                        opacity: 1;
                    }
                }

                @keyframes jo-confirm--window-popup {
                    to {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            </style>

            <div class="jo-confirm--main-container">
                <div class="jo-confirm--darken-screen jo-confirm--fade-in">
                    <div class="jo-confirm--window jo-confirm--window-popup">
                        <div class="jo-confirm--title-bar">
                            <div class="jo-confirm--title">${state.confirmTitle}</div>
                            <div
                                class="jo-confirm--close"
                                @click=${() => this.buttonClicked(false)}
                            >
                                &times;
                            </div>
                        </div>
                        <div class="jo-confirm--message">${state.confirmMessage}</div>
                        <div class="jo-confirm--buttons-row">
                            <jo-button
                                class="jo-global--padding-right-1-rem"
                                .type=${'ALERT_BUTTON_CANCEL'}
                                .text=${'Cancel'}
                                @joButtonClick=${() => this.buttonClicked(false)}
                            ></jo-button>

                            <jo-button
                                ${(0, test_id_directive_1.testId)('jo-confirm-yes-button')}
                                .type=${'ALERT_BUTTON_ACCEPT'}
                                .text=${'Yes'}
                                @joButtonClick=${() => this.buttonClicked(true)}
                            ></jo-button>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
exports.JOConfirm = JOConfirm;
(0, define_custom_element_1.defineCustomElement)('jo-confirm', JOConfirm);
