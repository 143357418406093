"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.publishOutboundQueueUpdatedMessage = void 0;
const graphql_1 = require("../../../../services/graphql");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function publishOutboundQueueUpdatedMessage() {
    await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            mutation {
                publishOutboundQueueUpdatedMessage
            }
        `);
}
exports.publishOutboundQueueUpdatedMessage = publishOutboundQueueUpdatedMessage;
