"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.signMessage = exports.generateKeyPair = void 0;
/**
 * This ignore is here because the openpgp package does not have types for its ESM export file but
 * we will assign those types below.
 */
// @ts-ignore
const OpenPgpEsm = __importStar(require("openpgp/dist/openpgp.min.mjs"));
const { generateKey, sign, createCleartextMessage, readPrivateKey } = OpenPgpEsm;
async function generateKeyPair() {
    const { publicKey, privateKey } = await generateKey({
        userIDs: [
            {
                name: `Jill's Office`,
            },
        ],
        curve: 'ed25519',
    });
    return {
        publicKey,
        privateKey,
    };
}
exports.generateKeyPair = generateKeyPair;
async function signMessage(message, privateKey) {
    const messageSignatureResult = await sign({
        message: await createCleartextMessage({ text: message }),
        signingKeys: await readPrivateKey({ armoredKey: privateKey }),
    });
    return messageSignatureResult;
}
exports.signMessage = signMessage;
