"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isEnv = exports.currentEnv = exports.EnvEnum = void 0;
var EnvEnum;
(function (EnvEnum) {
    EnvEnum["Prod"] = "production";
    EnvEnum["BetaProd"] = "beta-production";
    EnvEnum["Staging"] = "staging";
    EnvEnum["BetaStaging"] = "beta-staging";
    EnvEnum["DevAndroid"] = "development-android-emulator";
    EnvEnum["Dev"] = "development";
    EnvEnum["Testing"] = "Testing";
})(EnvEnum = exports.EnvEnum || (exports.EnvEnum = {}));
const hostToEnvMapping = {
    'app.jillsoffice.com': EnvEnum.Prod,
    'beta.app.jillsoffice.com': EnvEnum.BetaProd,
    'app.staging.jillsoffice.com': EnvEnum.Staging,
    'beta.app.staging.jillsoffice.com': EnvEnum.BetaStaging,
    '10.0.2.2': EnvEnum.DevAndroid,
};
function getEnvBasedOnUrl() {
    // globalThis.testEnv is set by web-test-runner.config.mjs
    if (globalThis.testEnv) {
        return EnvEnum.Testing;
    }
    const envFromHost = hostToEnvMapping[window.location.hostname];
    return envFromHost || EnvEnum.Dev;
}
exports.currentEnv = getEnvBasedOnUrl();
function isEnv(isItThisOne, checkThisOne = exports.currentEnv) {
    return checkThisOne === isItThisOne;
}
exports.isEnv = isEnv;
