"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.startLogoutDetector = exports.detectLogout = void 0;
const common_1 = require("@augment-vir/common");
const get_auth_keys_from_local_storage_1 = require("./get-auth-keys-from-local-storage");
let wasLoggedIn = false;
function detectLogout() {
    const authKeys = (0, get_auth_keys_from_local_storage_1.getAuthKeysFromLocalStorage)();
    const isLoggedIn = Object.values(authKeys).every(common_1.isTruthy);
    if (wasLoggedIn && !isLoggedIn) {
        document.body.innerHTML = '';
        window.location.href = '/';
    }
    wasLoggedIn = isLoggedIn;
}
exports.detectLogout = detectLogout;
function startLogoutDetector() {
    setInterval(() => detectLogout(), 500);
}
exports.startLogoutDetector = startLogoutDetector;
