"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOAudioPopUp = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const define_custom_element_1 = require("../../utilities/define-custom-element");
require("./jo-button.element");
const InitialState = {
    src: 'NOT-SET',
};
class JOAudioPopUp extends HTMLElement {
    constructor(src) {
        super();
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
        this.store.src = src;
    }
    buttonClicked(response) {
        if (response === true) {
            this.dispatchEvent(new CustomEvent('ok'));
        }
        else {
            this.dispatchEvent(new CustomEvent('cancel'));
        }
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-audio-popup--main-container {
                    font-family: sans-serif;
                }

                .jo-audio-popup--darken-screen {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.65);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: var(--z-index-3);
                }

                @media (max-width: 768px) {
                    .jo-audio-popup--darken-screen {
                        align-items: flex-start;
                    }
                }

                .jo-audio-popup--fade-in {
                    opacity: 0;
                    animation-name: jo-audio-popup--fade-in;
                    animation-duration: 0.125s;
                    animation-fill-mode: forwards;
                }

                .jo-audio-popup--window {
                    width: 35%;
                    background: white;
                    border-radius: 5px;
                    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
                }

                @media (max-width: 1280px) {
                    .jo-audio-popup--window {
                        width: 50%;
                    }
                }

                @media (max-width: 768px) {
                    .jo-audio-popup--window {
                        margin-top: 40%;
                        width: 75%;
                    }
                }

                .jo-audio-popup--window-popup {
                    opacity: 0;
                    transform: scale(0.5);
                    animation-name: jo-audio-popup--window-popup;
                    animation-duration: 0.15s;
                    animation-fill-mode: forwards;
                }

                .jo-audio-popup--title-bar {
                    background: var(--jo-primary);
                    color: white;
                    font-weight: bold;
                    padding: 1.2em;
                    border-bottom: 1px solid var(--jo-accent-background-light);
                    box-sizing: border-box;
                    display: flex;
                    align-content: center;
                    justify-content: space-between;
                }

                .jo-audio-popup--alertTitle {
                    font-weight: bold;
                }

                .jo-audio-popup--close {
                    color: var(--jo-text-default);
                    transform: scale(1.5);
                    cursor: pointer;
                }

                .jo-audio-popup--close:hover {
                    color: black;
                    transform: scale(1.65);
                }

                .jo-audio-popup--player {
                    padding: 1.5rem;
                    border-bottom: 1px solid var(--jo-accent-background-light);
                    box-sizing: border-box;
                    margin-left: auto;
                    margin-right: auto;
                }

                .jo-audio-popup--buttons-row {
                    background: var(--jo-accent-background-light);
                    padding: 1em;
                    display: flex;
                    justify-content: flex-end;
                }

                @keyframes jo-audio-popup--fade-in {
                    to {
                        opacity: 1;
                    }
                }

                @keyframes jo-audio-popup--window-popup {
                    to {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            </style>

            <div class="jo-audio-popup--main-container">
                <div class="jo-audio-popup--darken-screen jo-audio-popup--fade-in">
                    <div class="jo-audio-popup--window jo-audio-popup--window-popup">
                        <div class="jo-audio-popup--title-bar">
                            <div>Call Recording</div>
                        </div>
                        <div class="jo-audio-popup--player">
                            <audio controls style="width: 100%" .autoplay=${true}>
                                <source src=${state.src} />
                                Your browser does not support the audio element.
                            </audio>
                        </div>
                        <div class="jo-audio-popup--buttons-row">
                            <jo-button
                                id="jo-audio-popup-button"
                                .type=${'ALERT_BUTTON_ACCEPT'}
                                .text=${'Done'}
                                @joButtonClick=${() => this.buttonClicked(true)}
                            ></jo-button>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
exports.JOAudioPopUp = JOAudioPopUp;
(0, define_custom_element_1.defineCustomElement)('jo-audio-popup', JOAudioPopUp);
