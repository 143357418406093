"use strict";
// TODO we need to change this for widgets that do not reside on our own domain
Object.defineProperty(exports, "__esModule", { value: true });
exports.minutesList = exports.millitaryHours = exports.daysOfWeek = exports.defaultJillProfilePicutreUrl = exports.jillAuthRoles = exports.CSV_EMPTY_COLUMN_STRING = exports.newBillingSystemEffectiveMonth = exports.EMAIL_ALREADY_TAKEN = exports.DELETE_POD_CANCEL_COMPANIES_STILL_ASSIGNED_ERROR_PREFIX = exports.defaultJillAvatarUrl = exports.MOBILE_TOP_BAR_TITLES = exports.MOBILE_DEVICE_MEDIA_QUERY = exports.canadianProvinces = exports.usStates = exports.mailingCountries = exports.Industries = exports.TimezonesValuesToInternationalStandardsMap = exports.TimezonesValuesToDisplayMap = exports.TimezonesDisplayToValuesMap = exports.TimezonesForDisplay = exports.LOCAL_DURATION_EVENT_INFOS = exports.anonymousAuthJWTName = exports.clientPrivateKeyName = exports.clientPublicKeyName = exports.csrfTokenName = exports.NEW_APPOINTMENT_ACTION_ITEM_TITLE = exports.NEW_CHAT_NOTIFICATION_SOUND_URL = exports.NEW_CHAT_MESSAGE_NOTIFICATION_SOUND_URL = void 0;
exports.NEW_CHAT_MESSAGE_NOTIFICATION_SOUND_URL = 'https://s3.us-west-2.amazonaws.com/jillsoffice/pop.mp3';
exports.NEW_CHAT_NOTIFICATION_SOUND_URL = 'https://s3.us-west-2.amazonaws.com/jillsoffice/notificationSoundBling.mp3';
exports.NEW_APPOINTMENT_ACTION_ITEM_TITLE = 'Hooray, New Appointment!';
exports.csrfTokenName = 'jills-office-csrf-token';
exports.clientPublicKeyName = 'jills-office-client-public-key';
exports.clientPrivateKeyName = 'jills-office-client-private-key';
exports.anonymousAuthJWTName = 'anonymous-jills-office-auth-jwt';
exports.LOCAL_DURATION_EVENT_INFOS = 'LOCAL_DURATION_EVENT_INFOS';
exports.TimezonesForDisplay = [
    '(GMT-11:00) American Samoa',
    '(GMT-11:00) International Date Line West',
    '(GMT-11:00) Midway Island',
    '(GMT-10:00) Hawaii',
    '(GMT-09:00) Alaska',
    '(GMT-08:00) Pacific Time (US & Canada)',
    '(GMT-08:00) Tijuana',
    '(GMT-07:00) Arizona',
    '(GMT-07:00) Chihuahua',
    '(GMT-07:00) Mazatlan',
    '(GMT-07:00) Mountain Time (US & Canada)',
    '(GMT-06:00) Central America',
    '(GMT-06:00) Central Time (US & Canada)',
    '(GMT-06:00) Guadalajara',
    '(GMT-06:00) Mexico City',
    '(GMT-06:00) Monterrey',
    '(GMT-06:00) Saskatchewan',
    '(GMT-05:00) Bogota',
    '(GMT-05:00) Eastern Time (US & Canada)',
    '(GMT-05:00) Indiana (East)',
    '(GMT-05:00) Lima',
    '(GMT-05:00) Quito',
    '(GMT-04:00) Atlantic Time (Canada)',
    '(GMT-04:00) Caracas',
    '(GMT-04:00) Georgetown',
    '(GMT-04:00) La Paz',
    '(GMT-04:00) Santiago',
    '(GMT-03:30) Newfoundland',
    '(GMT-03:00) Brasilia',
    '(GMT-03:00) Buenos Aires',
    '(GMT-03:00) Greenland',
    '(GMT-03:00) Montevideo',
    '(GMT-02:00) Mid-Atlantic',
    '(GMT-01:00) Azores',
    '(GMT-01:00) Cape Verde Is.',
    '(GMT+00:00) Edinburgh',
    '(GMT+00:00) Lisbon',
    '(GMT+00:00) London',
    '(GMT+00:00) Monrovia',
    '(GMT+00:00) UTC',
    '(GMT+01:00) Amsterdam',
    '(GMT+01:00) Belgrade',
    '(GMT+01:00) Berlin',
    '(GMT+01:00) Bern',
    '(GMT+01:00) Bratislava',
    '(GMT+01:00) Brussels',
    '(GMT+01:00) Budapest',
    '(GMT+01:00) Casablanca',
    '(GMT+01:00) Copenhagen',
    '(GMT+01:00) Dublin',
    '(GMT+01:00) Ljubljana',
    '(GMT+01:00) Madrid',
    '(GMT+01:00) Paris',
    '(GMT+01:00) Prague',
    '(GMT+01:00) Rome',
    '(GMT+01:00) Sarajevo',
    '(GMT+01:00) Skopje',
    '(GMT+01:00) Stockholm',
    '(GMT+01:00) Vienna',
    '(GMT+01:00) Warsaw',
    '(GMT+01:00) West Central Africa',
    '(GMT+01:00) Zagreb',
    '(GMT+01:00) Zurich',
    '(GMT+02:00) Athens',
    '(GMT+02:00) Bucharest',
    '(GMT+02:00) Cairo',
    '(GMT+02:00) Harare',
    '(GMT+02:00) Helsinki',
    '(GMT+02:00) Jerusalem',
    '(GMT+02:00) Kaliningrad',
    '(GMT+02:00) Kyiv',
    '(GMT+02:00) Pretoria',
    '(GMT+02:00) Riga',
    '(GMT+02:00) Sofia',
    '(GMT+02:00) Tallinn',
    '(GMT+02:00) Vilnius',
    '(GMT+03:00) Baghdad',
    '(GMT+03:00) Istanbul',
    '(GMT+03:00) Kuwait',
    '(GMT+03:00) Minsk',
    '(GMT+03:00) Moscow',
    '(GMT+03:00) Nairobi',
    '(GMT+03:00) Riyadh',
    '(GMT+03:00) St. Petersburg',
    '(GMT+03:00) Volgograd',
    '(GMT+03:30) Tehran',
    '(GMT+04:00) Abu Dhabi',
    '(GMT+04:00) Baku',
    '(GMT+04:00) Muscat',
    '(GMT+04:00) Samara',
    '(GMT+04:00) Tbilisi',
    '(GMT+04:00) Yerevan',
    '(GMT+04:30) Kabul',
    '(GMT+05:00) Ekaterinburg',
    '(GMT+05:00) Islamabad',
    '(GMT+05:00) Karachi',
    '(GMT+05:00) Tashkent',
    '(GMT+05:30) Chennai',
    '(GMT+05:30) Kolkata',
    '(GMT+05:30) Mumbai',
    '(GMT+05:30) New Delhi',
    '(GMT+05:30) Sri Jayawardenepura',
    '(GMT+05:45) Kathmandu',
    '(GMT+06:00) Almaty',
    '(GMT+06:00) Astana',
    '(GMT+06:00) Dhaka',
    '(GMT+06:00) Urumqi',
    '(GMT+06:30) Rangoon',
    '(GMT+07:00) Bangkok',
    '(GMT+07:00) Hanoi',
    '(GMT+07:00) Jakarta',
    '(GMT+07:00) Krasnoyarsk',
    '(GMT+07:00) Novosibirsk',
    '(GMT+08:00) Beijing',
    '(GMT+08:00) Chongqing',
    '(GMT+08:00) Hong Kong',
    '(GMT+08:00) Irkutsk',
    '(GMT+08:00) Kuala Lumpur',
    '(GMT+08:00) Perth',
    '(GMT+08:00) Singapore',
    '(GMT+08:00) Taipei',
    '(GMT+08:00) Ulaanbaatar',
    '(GMT+09:00) Osaka',
    '(GMT+09:00) Sapporo',
    '(GMT+09:00) Seoul',
    '(GMT+09:00) Tokyo',
    '(GMT+09:00) Yakutsk',
    '(GMT+09:30) Adelaide',
    '(GMT+09:30) Darwin',
    '(GMT+10:00) Brisbane',
    '(GMT+10:00) Canberra',
    '(GMT+10:00) Guam',
    '(GMT+10:00) Hobart',
    '(GMT+10:00) Melbourne',
    '(GMT+10:00) Port Moresby',
    '(GMT+10:00) Sydney',
    '(GMT+10:00) Vladivostok',
    '(GMT+11:00) Magadan',
    '(GMT+11:00) New Caledonia',
    '(GMT+11:00) Solomon Is.',
    '(GMT+11:00) Srednekolymsk',
    '(GMT+12:00) Auckland',
    '(GMT+12:00) Fiji',
    '(GMT+12:00) Kamchatka',
    '(GMT+12:00) Marshall Is.',
    '(GMT+12:00) Wellington',
    '(GMT+12:45) Chatham Is.',
    "(GMT+13:00) Nuku'alofa",
    '(GMT+13:00) Samoa',
    '(GMT+13:00) Tokelau Is.',
];
exports.TimezonesDisplayToValuesMap = {
    '(GMT-11:00) American Samoa': 'American Samoa',
    '(GMT-11:00) International Date Line West': 'International Date Line West',
    '(GMT-11:00) Midway Island': 'Midway Island',
    '(GMT-10:00) Hawaii': 'Hawaii',
    '(GMT-09:00) Alaska': 'Alaska',
    '(GMT-08:00) Pacific Time (US & Canada)': 'Pacific Time (US & Canada)',
    '(GMT-08:00) Tijuana': 'Tijuana',
    '(GMT-07:00) Arizona': 'Arizona',
    '(GMT-07:00) Chihuahua': 'Chihuahua',
    '(GMT-07:00) Mazatlan': 'Mazatlan',
    '(GMT-07:00) Mountain Time (US & Canada)': 'Mountain Time (US & Canada)',
    '(GMT-06:00) Central America': 'Central America',
    '(GMT-06:00) Central Time (US & Canada)': 'Central Time (US & Canada)',
    '(GMT-06:00) Guadalajara': 'Guadalajara',
    '(GMT-06:00) Mexico City': 'Mexico City',
    '(GMT-06:00) Monterrey': 'Monterrey',
    '(GMT-06:00) Saskatchewan': 'Saskatchewan',
    '(GMT-05:00) Bogota': 'Bogota',
    '(GMT-05:00) Eastern Time (US & Canada)': 'Eastern Time (US & Canada)',
    '(GMT-05:00) Indiana (East)': 'Indiana (East)',
    '(GMT-05:00) Lima': 'Lima',
    '(GMT-05:00) Quito': 'Quito',
    '(GMT-04:00) Atlantic Time (Canada)': 'Atlantic Time (Canada)',
    '(GMT-04:00) Caracas': 'Caracas',
    '(GMT-04:00) Georgetown': 'Georgetown',
    '(GMT-04:00) La Paz': 'La Paz',
    '(GMT-04:00) Santiago': 'Santiago',
    '(GMT-03:30) Newfoundland': 'Newfoundland',
    '(GMT-03:00) Brasilia': 'Brasilia',
    '(GMT-03:00) Buenos Aires': 'Buenos Aires',
    '(GMT-03:00) Greenland': 'Greenland',
    '(GMT-03:00) Montevideo': 'Montevideo',
    '(GMT-02:00) Mid-Atlantic': 'Mid-Atlantic',
    '(GMT-01:00) Azores': 'Azores',
    '(GMT-01:00) Cape Verde Is.': 'Cape Verde Is.',
    '(GMT+00:00) Edinburgh': 'Edinburgh',
    '(GMT+00:00) Lisbon': 'Lisbon',
    '(GMT+00:00) London': 'London',
    '(GMT+00:00) Monrovia': 'Monrovia',
    '(GMT+00:00) UTC': 'UTC',
    '(GMT+01:00) Amsterdam': 'Amsterdam',
    '(GMT+01:00) Belgrade': 'Belgrade',
    '(GMT+01:00) Berlin': 'Berlin',
    '(GMT+01:00) Bern': 'Bern',
    '(GMT+01:00) Bratislava': 'Bratislava',
    '(GMT+01:00) Brussels': 'Brussels',
    '(GMT+01:00) Budapest': 'Budapest',
    '(GMT+01:00) Casablanca': 'Casablanca',
    '(GMT+01:00) Copenhagen': 'Copenhagen',
    '(GMT+01:00) Dublin': 'Dublin',
    '(GMT+01:00) Ljubljana': 'Ljubljana',
    '(GMT+01:00) Madrid': 'Madrid',
    '(GMT+01:00) Paris': 'Paris',
    '(GMT+01:00) Prague': 'Prague',
    '(GMT+01:00) Rome': 'Rome',
    '(GMT+01:00) Sarajevo': 'Sarajevo',
    '(GMT+01:00) Skopje': 'Skopje',
    '(GMT+01:00) Stockholm': 'Stockholm',
    '(GMT+01:00) Vienna': 'Vienna',
    '(GMT+01:00) Warsaw': 'Warsaw',
    '(GMT+01:00) West Central Africa': 'West Central Africa',
    '(GMT+01:00) Zagreb': 'Zagreb',
    '(GMT+01:00) Zurich': 'Zurich',
    '(GMT+02:00) Athens': 'Athens',
    '(GMT+02:00) Bucharest': 'Bucharest',
    '(GMT+02:00) Cairo': 'Cairo',
    '(GMT+02:00) Harare': 'Harare',
    '(GMT+02:00) Helsinki': 'Helsinki',
    '(GMT+02:00) Jerusalem': 'Jerusalem',
    '(GMT+02:00) Kaliningrad': 'Kaliningrad',
    '(GMT+02:00) Kyiv': 'Kyiv',
    '(GMT+02:00) Pretoria': 'Pretoria',
    '(GMT+02:00) Riga': 'Riga',
    '(GMT+02:00) Sofia': 'Sofia',
    '(GMT+02:00) Tallinn': 'Tallinn',
    '(GMT+02:00) Vilnius': 'Vilnius',
    '(GMT+03:00) Baghdad': 'Baghdad',
    '(GMT+03:00) Istanbul': 'Istanbul',
    '(GMT+03:00) Kuwait': 'Kuwait',
    '(GMT+03:00) Minsk': 'Minsk',
    '(GMT+03:00) Moscow': 'Moscow',
    '(GMT+03:00) Nairobi': 'Nairobi',
    '(GMT+03:00) Riyadh': 'Riyadh',
    '(GMT+03:00) St. Petersburg': 'St. Petersburg',
    '(GMT+03:00) Volgograd': 'Volgograd',
    '(GMT+03:30) Tehran': 'Tehran',
    '(GMT+04:00) Abu Dhabi': 'Abu Dhabi',
    '(GMT+04:00) Baku': 'Baku',
    '(GMT+04:00) Muscat': 'Muscat',
    '(GMT+04:00) Samara': 'Samara',
    '(GMT+04:00) Tbilisi': 'Tbilisi',
    '(GMT+04:00) Yerevan': 'Yerevan',
    '(GMT+04:30) Kabul': 'Kabul',
    '(GMT+05:00) Ekaterinburg': 'Ekaterinburg',
    '(GMT+05:00) Islamabad': 'Islamabad',
    '(GMT+05:00) Karachi': 'Karachi',
    '(GMT+05:00) Tashkent': 'Tashkent',
    '(GMT+05:30) Chennai': 'Chennai',
    '(GMT+05:30) Kolkata': 'Kolkata',
    '(GMT+05:30) Mumbai': 'Mumbai',
    '(GMT+05:30) New Delhi': 'New Delhi',
    '(GMT+05:30) Sri Jayawardenepura': 'Sri Jayawardenepura',
    '(GMT+05:45) Kathmandu': 'Kathmandu',
    '(GMT+06:00) Almaty': 'Almaty',
    '(GMT+06:00) Astana': 'Astana',
    '(GMT+06:00) Dhaka': 'Dhaka',
    '(GMT+06:00) Urumqi': 'Urumqi',
    '(GMT+06:30) Rangoon': 'Rangoon',
    '(GMT+07:00) Bangkok': 'Bangkok',
    '(GMT+07:00) Hanoi': 'Hanoi',
    '(GMT+07:00) Jakarta': 'Jakarta',
    '(GMT+07:00) Krasnoyarsk': 'Krasnoyarsk',
    '(GMT+07:00) Novosibirsk': 'Novosibirsk',
    '(GMT+08:00) Beijing': 'Beijing',
    '(GMT+08:00) Chongqing': 'Chongqing',
    '(GMT+08:00) Hong Kong': 'Hong Kong',
    '(GMT+08:00) Irkutsk': 'Irkutsk',
    '(GMT+08:00) Kuala Lumpur': 'Kuala Lumpur',
    '(GMT+08:00) Perth': 'Perth',
    '(GMT+08:00) Singapore': 'Singapore',
    '(GMT+08:00) Taipei': 'Taipei',
    '(GMT+08:00) Ulaanbaatar': 'Ulaanbaatar',
    '(GMT+09:00) Osaka': 'Osaka',
    '(GMT+09:00) Sapporo': 'Sapporo',
    '(GMT+09:00) Seoul': 'Seoul',
    '(GMT+09:00) Tokyo': 'Tokyo',
    '(GMT+09:00) Yakutsk': 'Yakutsk',
    '(GMT+09:30) Adelaide': 'Adelaide',
    '(GMT+09:30) Darwin': 'Darwin',
    '(GMT+10:00) Brisbane': 'Brisbane',
    '(GMT+10:00) Canberra': 'Canberra',
    '(GMT+10:00) Guam': 'Guam',
    '(GMT+10:00) Hobart': 'Hobart',
    '(GMT+10:00) Melbourne': 'Melbourne',
    '(GMT+10:00) Port Moresby': 'Port Moresby',
    '(GMT+10:00) Sydney': 'Sydney',
    '(GMT+10:00) Vladivostok': 'Vladivostok',
    '(GMT+11:00) Magadan': 'Magadan',
    '(GMT+11:00) New Caledonia': 'New Caledonia',
    '(GMT+11:00) Solomon Is.': 'Solomon Is.',
    '(GMT+11:00) Srednekolymsk': 'Srednekolymsk',
    '(GMT+12:00) Auckland': 'Auckland',
    '(GMT+12:00) Fiji': 'Fiji',
    '(GMT+12:00) Kamchatka': 'Kamchatka',
    '(GMT+12:00) Marshall Is.': 'Marshall Is.',
    '(GMT+12:00) Wellington': 'Wellington',
    '(GMT+12:45) Chatham Is.': 'Chatham Is.',
    "(GMT+13:00) Nuku'alofa": "Nuku'alofa",
    '(GMT+13:00) Samoa': 'Samoa',
    '(GMT+13:00) Tokelau Is.': 'Tokelau Is.',
};
exports.TimezonesValuesToDisplayMap = {
    'American Samoa': '(GMT-11:00) American Samoa',
    'International Date Line West': '(GMT-11:00) International Date Line West',
    'Midway Island': '(GMT-11:00) Midway Island',
    Hawaii: '(GMT-10:00) Hawaii',
    Alaska: '(GMT-09:00) Alaska',
    'Pacific Time (US & Canada)': '(GMT-08:00) Pacific Time (US & Canada)',
    Tijuana: '(GMT-08:00) Tijuana',
    Arizona: '(GMT-07:00) Arizona',
    Chihuahua: '(GMT-07:00) Chihuahua',
    Mazatlan: '(GMT-07:00) Mazatlan',
    'Mountain Time (US & Canada)': '(GMT-07:00) Mountain Time (US & Canada)',
    'Central America': '(GMT-06:00) Central America',
    'Central Time (US & Canada)': '(GMT-06:00) Central Time (US & Canada)',
    Guadalajara: '(GMT-06:00) Guadalajara',
    'Mexico City': '(GMT-06:00) Mexico City',
    Monterrey: '(GMT-06:00) Monterrey',
    Saskatchewan: '(GMT-06:00) Saskatchewan',
    Bogota: '(GMT-05:00) Bogota',
    'Eastern Time (US & Canada)': '(GMT-05:00) Eastern Time (US & Canada)',
    'Indiana (East)': '(GMT-05:00) Indiana (East)',
    Lima: '(GMT-05:00) Lima',
    Quito: '(GMT-05:00) Quito',
    'Atlantic Time (Canada)': '(GMT-04:00) Atlantic Time (Canada)',
    Caracas: '(GMT-04:00) Caracas',
    Georgetown: '(GMT-04:00) Georgetown',
    'La Paz': '(GMT-04:00) La Paz',
    Santiago: '(GMT-04:00) Santiago',
    Newfoundland: '(GMT-03:30) Newfoundland',
    Brasilia: '(GMT-03:00) Brasilia',
    'Buenos Aires': '(GMT-03:00) Buenos Aires',
    Greenland: '(GMT-03:00) Greenland',
    Montevideo: '(GMT-03:00) Montevideo',
    'Mid-Atlantic': '(GMT-02:00) Mid-Atlantic',
    Azores: '(GMT-01:00) Azores',
    'Cape Verde Is.': '(GMT-01:00) Cape Verde Is.',
    Edinburgh: '(GMT+00:00) Edinburgh',
    Lisbon: '(GMT+00:00) Lisbon',
    London: '(GMT+00:00) London',
    Monrovia: '(GMT+00:00) Monrovia',
    UTC: '(GMT+00:00) UTC',
    Amsterdam: '(GMT+01:00) Amsterdam',
    Belgrade: '(GMT+01:00) Belgrade',
    Berlin: '(GMT+01:00) Berlin',
    Bern: '(GMT+01:00) Bern',
    Bratislava: '(GMT+01:00) Bratislava',
    Brussels: '(GMT+01:00) Brussels',
    Budapest: '(GMT+01:00) Budapest',
    Casablanca: '(GMT+01:00) Casablanca',
    Copenhagen: '(GMT+01:00) Copenhagen',
    Dublin: '(GMT+01:00) Dublin',
    Ljubljana: '(GMT+01:00) Ljubljana',
    Madrid: '(GMT+01:00) Madrid',
    Paris: '(GMT+01:00) Paris',
    Prague: '(GMT+01:00) Prague',
    Rome: '(GMT+01:00) Rome',
    Sarajevo: '(GMT+01:00) Sarajevo',
    Skopje: '(GMT+01:00) Skopje',
    Stockholm: '(GMT+01:00) Stockholm',
    Vienna: '(GMT+01:00) Vienna',
    Warsaw: '(GMT+01:00) Warsaw',
    'West Central Africa': '(GMT+01:00) West Central Africa',
    Zagreb: '(GMT+01:00) Zagreb',
    Zurich: '(GMT+01:00) Zurich',
    Athens: '(GMT+02:00) Athens',
    Bucharest: '(GMT+02:00) Bucharest',
    Cairo: '(GMT+02:00) Cairo',
    Harare: '(GMT+02:00) Harare',
    Helsinki: '(GMT+02:00) Helsinki',
    Jerusalem: '(GMT+02:00) Jerusalem',
    Kaliningrad: '(GMT+02:00) Kaliningrad',
    Kyiv: '(GMT+02:00) Kyiv',
    Pretoria: '(GMT+02:00) Pretoria',
    Riga: '(GMT+02:00) Riga',
    Sofia: '(GMT+02:00) Sofia',
    Tallinn: '(GMT+02:00) Tallinn',
    Vilnius: '(GMT+02:00) Vilnius',
    Baghdad: '(GMT+03:00) Baghdad',
    Istanbul: '(GMT+03:00) Istanbul',
    Kuwait: '(GMT+03:00) Kuwait',
    Minsk: '(GMT+03:00) Minsk',
    Moscow: '(GMT+03:00) Moscow',
    Nairobi: '(GMT+03:00) Nairobi',
    Riyadh: '(GMT+03:00) Riyadh',
    'St. Petersburg': '(GMT+03:00) St. Petersburg',
    Volgograd: '(GMT+03:00) Volgograd',
    Tehran: '(GMT+03:30) Tehran',
    'Abu Dhabi': '(GMT+04:00) Abu Dhabi',
    Baku: '(GMT+04:00) Baku',
    Muscat: '(GMT+04:00) Muscat',
    Samara: '(GMT+04:00) Samara',
    Tbilisi: '(GMT+04:00) Tbilisi',
    Yerevan: '(GMT+04:00) Yerevan',
    Kabul: '(GMT+04:30) Kabul',
    Ekaterinburg: '(GMT+05:00) Ekaterinburg',
    Islamabad: '(GMT+05:00) Islamabad',
    Karachi: '(GMT+05:00) Karachi',
    Tashkent: '(GMT+05:00) Tashkent',
    Chennai: '(GMT+05:30) Chennai',
    Kolkata: '(GMT+05:30) Kolkata',
    Mumbai: '(GMT+05:30) Mumbai',
    'New Delhi': '(GMT+05:30) New Delhi',
    'Sri Jayawardenepura': '(GMT+05:30) Sri Jayawardenepura',
    Kathmandu: '(GMT+05:45) Kathmandu',
    Almaty: '(GMT+06:00) Almaty',
    Astana: '(GMT+06:00) Astana',
    Dhaka: '(GMT+06:00) Dhaka',
    Urumqi: '(GMT+06:00) Urumqi',
    Rangoon: '(GMT+06:30) Rangoon',
    Bangkok: '(GMT+07:00) Bangkok',
    Hanoi: '(GMT+07:00) Hanoi',
    Jakarta: '(GMT+07:00) Jakarta',
    Krasnoyarsk: '(GMT+07:00) Krasnoyarsk',
    Novosibirsk: '(GMT+07:00) Novosibirsk',
    Beijing: '(GMT+08:00) Beijing',
    Chongqing: '(GMT+08:00) Chongqing',
    'Hong Kong': '(GMT+08:00) Hong Kong',
    Irkutsk: '(GMT+08:00) Irkutsk',
    'Kuala Lumpur': '(GMT+08:00) Kuala Lumpur',
    Perth: '(GMT+08:00) Perth',
    Singapore: '(GMT+08:00) Singapore',
    Taipei: '(GMT+08:00) Taipei',
    Ulaanbaatar: '(GMT+08:00) Ulaanbaatar',
    Osaka: '(GMT+09:00) Osaka',
    Sapporo: '(GMT+09:00) Sapporo',
    Seoul: '(GMT+09:00) Seoul',
    Tokyo: '(GMT+09:00) Tokyo',
    Yakutsk: '(GMT+09:00) Yakutsk',
    Adelaide: '(GMT+09:30) Adelaide',
    Darwin: '(GMT+09:30) Darwin',
    Brisbane: '(GMT+10:00) Brisbane',
    Canberra: '(GMT+10:00) Canberra',
    Guam: '(GMT+10:00) Guam',
    Hobart: '(GMT+10:00) Hobart',
    Melbourne: '(GMT+10:00) Melbourne',
    'Port Moresby': '(GMT+10:00) Port Moresby',
    Sydney: '(GMT+10:00) Sydney',
    Vladivostok: '(GMT+10:00) Vladivostok',
    Magadan: '(GMT+11:00) Magadan',
    'New Caledonia': '(GMT+11:00) New Caledonia',
    'Solomon Is.': '(GMT+11:00) Solomon Is.',
    Srednekolymsk: '(GMT+11:00) Srednekolymsk',
    Auckland: '(GMT+12:00) Auckland',
    Fiji: '(GMT+12:00) Fiji',
    Kamchatka: '(GMT+12:00) Kamchatka',
    'Marshall Is.': '(GMT+12:00) Marshall Is.',
    Wellington: '(GMT+12:00) Wellington',
    'Chatham Is.': '(GMT+12:45) Chatham Is.',
    "Nuku'alofa": "(GMT+13:00) Nuku'alofa",
    Samoa: '(GMT+13:00) Samoa',
    'Tokelau Is.': '(GMT+13:00) Tokelau Is.',
};
exports.TimezonesValuesToInternationalStandardsMap = {
    'International Date Line West': 'Etc/GMT+12',
    'Midway Island': 'Pacific/Midway',
    'American Samoa': 'Pacific/Pago_Pago',
    Hawaii: 'Pacific/Honolulu',
    Alaska: 'America/Juneau',
    'Pacific Time (US & Canada)': 'America/Los_Angeles',
    Tijuana: 'America/Tijuana',
    'Mountain Time (US & Canada)': 'America/Denver',
    Arizona: 'America/Phoenix',
    Chihuahua: 'America/Chihuahua',
    Mazatlan: 'America/Mazatlan',
    'Central Time (US & Canada)': 'America/Chicago',
    Saskatchewan: 'America/Regina',
    Guadalajara: 'America/Mexico_City',
    'Mexico City': 'America/Mexico_City',
    Monterrey: 'America/Monterrey',
    'Central America': 'America/Guatemala',
    'Eastern Time (US & Canada)': 'America/New_York',
    'Indiana (East)': 'America/Indiana/Indianapolis',
    Bogota: 'America/Bogota',
    Lima: 'America/Lima',
    Quito: 'America/Lima',
    'Atlantic Time (Canada)': 'America/Halifax',
    Caracas: 'America/Caracas',
    'La Paz': 'America/La_Paz',
    Santiago: 'America/Santiago',
    Newfoundland: 'America/St_Johns',
    Brasilia: 'America/Sao_Paulo',
    'Buenos Aires': 'America/Argentina/Buenos_Aires',
    Montevideo: 'America/Montevideo',
    Georgetown: 'America/Guyana',
    'Puerto Rico': 'America/Puerto_Rico',
    Greenland: 'America/Godthab',
    'Mid-Atlantic': 'Atlantic/South_Georgia',
    Azores: 'Atlantic/Azores',
    'Cape Verde Is.': 'Atlantic/Cape_Verde',
    Dublin: 'Europe/Dublin',
    Edinburgh: 'Europe/London',
    Lisbon: 'Europe/Lisbon',
    London: 'Europe/London',
    Casablanca: 'Africa/Casablanca',
    Monrovia: 'Africa/Monrovia',
    UTC: 'Etc/UTC',
    Belgrade: 'Europe/Belgrade',
    Bratislava: 'Europe/Bratislava',
    Budapest: 'Europe/Budapest',
    Ljubljana: 'Europe/Ljubljana',
    Prague: 'Europe/Prague',
    Sarajevo: 'Europe/Sarajevo',
    Skopje: 'Europe/Skopje',
    Warsaw: 'Europe/Warsaw',
    Zagreb: 'Europe/Zagreb',
    Brussels: 'Europe/Brussels',
    Copenhagen: 'Europe/Copenhagen',
    Madrid: 'Europe/Madrid',
    Paris: 'Europe/Paris',
    Amsterdam: 'Europe/Amsterdam',
    Berlin: 'Europe/Berlin',
    Bern: 'Europe/Zurich',
    Zurich: 'Europe/Zurich',
    Rome: 'Europe/Rome',
    Stockholm: 'Europe/Stockholm',
    Vienna: 'Europe/Vienna',
    'West Central Africa': 'Africa/Algiers',
    Bucharest: 'Europe/Bucharest',
    Cairo: 'Africa/Cairo',
    Helsinki: 'Europe/Helsinki',
    Kyiv: 'Europe/Kiev',
    Riga: 'Europe/Riga',
    Sofia: 'Europe/Sofia',
    Tallinn: 'Europe/Tallinn',
    Vilnius: 'Europe/Vilnius',
    Athens: 'Europe/Athens',
    Istanbul: 'Europe/Istanbul',
    Minsk: 'Europe/Minsk',
    Jerusalem: 'Asia/Jerusalem',
    Harare: 'Africa/Harare',
    Pretoria: 'Africa/Johannesburg',
    Kaliningrad: 'Europe/Kaliningrad',
    Moscow: 'Europe/Moscow',
    'St. Petersburg': 'Europe/Moscow',
    Volgograd: 'Europe/Volgograd',
    Samara: 'Europe/Samara',
    Kuwait: 'Asia/Kuwait',
    Riyadh: 'Asia/Riyadh',
    Nairobi: 'Africa/Nairobi',
    Baghdad: 'Asia/Baghdad',
    Tehran: 'Asia/Tehran',
    'Abu Dhabi': 'Asia/Muscat',
    Muscat: 'Asia/Muscat',
    Baku: 'Asia/Baku',
    Tbilisi: 'Asia/Tbilisi',
    Yerevan: 'Asia/Yerevan',
    Kabul: 'Asia/Kabul',
    Ekaterinburg: 'Asia/Yekaterinburg',
    Islamabad: 'Asia/Karachi',
    Karachi: 'Asia/Karachi',
    Tashkent: 'Asia/Tashkent',
    Chennai: 'Asia/Kolkata',
    Kolkata: 'Asia/Kolkata',
    Mumbai: 'Asia/Kolkata',
    'New Delhi': 'Asia/Kolkata',
    Kathmandu: 'Asia/Kathmandu',
    Astana: 'Asia/Dhaka',
    Dhaka: 'Asia/Dhaka',
    'Sri Jayawardenepura': 'Asia/Colombo',
    Almaty: 'Asia/Almaty',
    Novosibirsk: 'Asia/Novosibirsk',
    Rangoon: 'Asia/Rangoon',
    Bangkok: 'Asia/Bangkok',
    Hanoi: 'Asia/Bangkok',
    Jakarta: 'Asia/Jakarta',
    Krasnoyarsk: 'Asia/Krasnoyarsk',
    Beijing: 'Asia/Shanghai',
    Chongqing: 'Asia/Chongqing',
    'Hong Kong': 'Asia/Hong_Kong',
    Urumqi: 'Asia/Urumqi',
    'Kuala Lumpur': 'Asia/Kuala_Lumpur',
    Singapore: 'Asia/Singapore',
    Taipei: 'Asia/Taipei',
    Perth: 'Australia/Perth',
    Irkutsk: 'Asia/Irkutsk',
    Ulaanbaatar: 'Asia/Ulaanbaatar',
    Seoul: 'Asia/Seoul',
    Osaka: 'Asia/Tokyo',
    Sapporo: 'Asia/Tokyo',
    Tokyo: 'Asia/Tokyo',
    Yakutsk: 'Asia/Yakutsk',
    Darwin: 'Australia/Darwin',
    Adelaide: 'Australia/Adelaide',
    Canberra: 'Australia/Melbourne',
    Melbourne: 'Australia/Melbourne',
    Sydney: 'Australia/Sydney',
    Brisbane: 'Australia/Brisbane',
    Hobart: 'Australia/Hobart',
    Vladivostok: 'Asia/Vladivostok',
    Guam: 'Pacific/Guam',
    'Port Moresby': 'Pacific/Port_Moresby',
    Magadan: 'Asia/Magadan',
    Srednekolymsk: 'Asia/Srednekolymsk',
    'Solomon Is.': 'Pacific/Guadalcanal',
    'New Caledonia': 'Pacific/Noumea',
    Fiji: 'Pacific/Fiji',
    Kamchatka: 'Asia/Kamchatka',
    'Marshall Is.': 'Pacific/Majuro',
    Auckland: 'Pacific/Auckland',
    Wellington: 'Pacific/Auckland',
    "Nuku'alofa": 'Pacific/Tongatapu',
    'Tokelau Is.': 'Pacific/Fakaofo',
    'Chatham Is.': 'Pacific/Chatham',
    Samoa: 'Pacific/Apia',
};
exports.Industries = [
    'Accounting/Finance',
    'Attorney/Legal',
    'Education',
    'Entertainment',
    'General Business/Consulting',
    'Health Services',
    'Home Services',
    'Insurance',
    'Marketing',
    'Real Estate',
    'Retail',
    'Technology',
    'Travel',
    'Other',
];
exports.mailingCountries = [
    'United States',
    'Canada',
];
exports.usStates = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Columbia',
    'Florida',
    'Georgia',
    'Guam',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Northern Marianas Islands',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Puerto Rico',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Virgin Islands',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
];
exports.canadianProvinces = [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Northwest Territories',
    'Nova Scotia',
    'Nunavut',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Yukon',
];
exports.MOBILE_DEVICE_MEDIA_QUERY = '(max-device-width: 768px)';
exports.MOBILE_TOP_BAR_TITLES = {
    '/': 'Dashboard',
    '/customer/action-items': 'Action Items',
    '/customer/contact-list': 'Contacts',
    '/customer/contact': 'Contact',
    '/customer/voicemail': 'Voicemail',
    '/customer/call-instructions': 'Call Instructions',
    '/customer/outbound-call-list': 'Outbound Call List',
    '/customer/company-profile': 'Company Profile',
    '/customer/users': 'Users',
    '/customer/employee/edit': 'Update User',
    '/customer/billing': 'Billing',
    '/customer/invoice': 'Invoice',
    '/customer/call-log': 'Call Log',
    '/customer/partner-discounts': 'Partner Discounts',
    '/customer/chat-widget-script-generator': 'Chat Widget Builder',
    '/customer/training': 'Training',
    '/customer/contact-us': 'Contact Us',
    '/customer/call-instructions/services-pricing': 'Services/Pricing',
    '/customer/call-instructions/general-setup': 'General Setup',
    '/customer/call-instructions/script-pages': 'Script Pages',
};
exports.defaultJillAvatarUrl = 'https://jills-office-public-hosted-images.s3-us-west-2.amazonaws.com/jill-avatar-1.jpg';
exports.DELETE_POD_CANCEL_COMPANIES_STILL_ASSIGNED_ERROR_PREFIX = 'DELETE_POD_CANCEL_COMPANIES_STILL_ASSIGNED: ';
exports.EMAIL_ALREADY_TAKEN = 'EMAIL_ALREADY_TAKEN';
exports.newBillingSystemEffectiveMonth = 'may-2021';
exports.CSV_EMPTY_COLUMN_STRING = 'N/A';
exports.jillAuthRoles = [
    'JILL',
    'JILL_OUTBOUND',
    'JILL_MANAGER',
    'JILL_BUSINESS_DEVELOPMENT',
    'JILL_EXECUTIVE',
];
exports.defaultJillProfilePicutreUrl = 'https://jills-office-public-hosted-images.s3-us-west-2.amazonaws.com/jill-avatar-1.png';
exports.daysOfWeek = [
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
    'SUNDAY',
];
exports.millitaryHours = Array(24)
    .fill(null)
    .map((_, i) => i);
exports.minutesList = Array(60)
    .fill(null)
    .map((_, i) => i);
