"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidPhoneNumber = void 0;
/**
 * Checks if a phone number is a valid phone number in the United States or Canada in E164 format.
 * Make sure you format the phone number in E164 format before passing in to this function
 *
 * @param {string} e164PhoneNumber - The phone number to be validated.
 * @returns {boolean} Whether the phone number is in E164 format or not.
 */
function isValidPhoneNumber(e164PhoneNumber) {
    const regex = /^\+1[2-9]\d{9}$/;
    return regex.test(e164PhoneNumber);
}
exports.isValidPhoneNumber = isValidPhoneNumber;
