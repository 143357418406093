"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoAudioButton = void 0;
const element_vir_1 = require("element-vir");
exports.JoAudioButton = (0, element_vir_1.defineElement)()({
    tagName: 'jo-audio-button',
    styles: (0, element_vir_1.css) `
        :host {
            display: block;
        }

        .button-icon {
            color: var(--jo-primary);
            font-size: 18px;
            padding-left: 0.5rem;
        }

        .button-icon:hover {
            color: var(--jo-primary-light);
        }
    `,
    events: {
        updateCurrentlyPlayingAudio: (0, element_vir_1.defineElementEvent)(),
    },
    renderCallback: ({ dispatch, events, inputs }) => {
        return (0, element_vir_1.html) `
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

            ${(0, element_vir_1.renderIf)(inputs.urlOfNewAudioToPlay != undefined &&
            inputs.urlOfNewAudioToPlay.trim() !== '' &&
            inputs.currentlyPlayingAudio?.src.toString() === inputs.urlOfNewAudioToPlay, (0, element_vir_1.html) `
                    <span
                        class="material-icons button-icon"
                        ${(0, element_vir_1.listen)('click', () => {
            stopPlayingAudio(inputs.currentlyPlayingAudio, dispatch, events);
        })}
                    >
                        stop_circle
                    </span>
                `, (0, element_vir_1.html) `
                    <span
                        class="material-icons button-icon"
                        ${(0, element_vir_1.listen)('click', () => {
            inputs.currentlyPlayingAudio?.pause();
            const audio = new Audio(inputs.urlOfNewAudioToPlay);
            audio.addEventListener('ended', () => {
                stopPlayingAudio(inputs.currentlyPlayingAudio, dispatch, events);
            });
            dispatch(new events.updateCurrentlyPlayingAudio(audio));
            audio.play();
        })}
                    >
                        play_circle_outline
                    </span>
                `)}
        `;
    },
});
function stopPlayingAudio(currentlyPlayingAudio, dispatch, events) {
    currentlyPlayingAudio?.pause();
    dispatch(new events.updateCurrentlyPlayingAudio(undefined));
}
