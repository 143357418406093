"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isEnvCategory = exports.getEnvCategory = exports.currentEnvCategory = void 0;
const env_1 = require("./env");
const envCategoryMapping = {
    [env_1.EnvEnum.Prod]: env_1.EnvEnum.Prod,
    [env_1.EnvEnum.BetaProd]: env_1.EnvEnum.Prod,
    [env_1.EnvEnum.Staging]: env_1.EnvEnum.Staging,
    [env_1.EnvEnum.BetaStaging]: env_1.EnvEnum.Staging,
    [env_1.EnvEnum.DevAndroid]: env_1.EnvEnum.Dev,
    [env_1.EnvEnum.Dev]: env_1.EnvEnum.Dev,
    [env_1.EnvEnum.Testing]: env_1.EnvEnum.Dev,
};
exports.currentEnvCategory = getEnvCategory();
function getEnvCategory(envToCheck = env_1.currentEnv) {
    return envCategoryMapping[envToCheck];
}
exports.getEnvCategory = getEnvCategory;
function isEnvCategory(envToCheck) {
    return exports.currentEnvCategory === envToCheck;
}
exports.isEnvCategory = isEnvCategory;
