"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.testIdSelector = exports.testId = void 0;
const element_vir_1 = require("element-vir");
const lit_1 = require("lit");
const directive_js_1 = require("lit/directive.js");
const testIdAttribute = 'data-test-id';
function testId(inputTestId) {
    return testIdDirective(inputTestId);
}
exports.testId = testId;
function testIdSelector(inputTestId) {
    return `[${testIdAttribute}="${inputTestId}"]`;
}
exports.testIdSelector = testIdSelector;
const testIdDirective = (0, directive_js_1.directive)(class extends directive_js_1.Directive {
    constructor(partInfo) {
        super(partInfo);
        this.element = (0, element_vir_1.extractElement)(partInfo, 'testId');
    }
    render(testId) {
        this.element.setAttribute(testIdAttribute, testId);
        return lit_1.noChange;
    }
});
