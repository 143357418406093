"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getForMonth = exports.getCurrentForMonth = exports.isTimeStampWithinXSecondsAgo = exports.getForMonthCurrentMonth = exports.getForMonthPreviousMonth = exports.getForMonthForDate = exports.getStartOfNextMonthForForMonth = exports.getStartOfCurrentMonthForForMonth = exports.getStartOfNextMonthInMountainTime = exports.getStartOfCurrentMonthInMountainTime = exports.getStartOfPreviousMonthInMountainTime = exports.getStartOfNextMonthForDateInMountainTime = exports.getStartOfCurrentMonthForDateInMountainTime = exports.getStartOfPreviousMonthForDateInMountainTime = exports.checkForMonthFormatCorrect = exports.millisecondsToHoursMinutesSecondsInSemicolonFormat = exports.millisecondsToHoursMinutesSeconds = exports.getBeginningOfYesterdayInMountainTime = exports.getBeginningOfTodayInMountainTime = exports.getNewInvoiceDueDateInMountainTime = exports.subtractMountainTimeOffset = exports.addMountainTimeOffset = exports.nowInMountainTime = exports.fifteenSecondsInMilliseconds = exports.oneDayInMilliseconds = exports.oneHourInMilliseconds = exports.oneMinuteInMilliseconds = exports.oneSecondInMilliseconds = exports.newBillingSystemForMonth = void 0;
const common_1 = require("@augment-vir/common");
exports.newBillingSystemForMonth = 'may-2021';
exports.oneSecondInMilliseconds = 1000;
exports.oneMinuteInMilliseconds = 60000;
exports.oneHourInMilliseconds = 3600000;
exports.oneDayInMilliseconds = exports.oneHourInMilliseconds * 24;
exports.fifteenSecondsInMilliseconds = 15000;
// TODO it would probably be nice to curry this in the dateInMountainTime function
const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
    timeZone: 'America/Denver',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
});
function nowInMountainTime() {
    const nowInMountainTimeDateString = dateTimeFormat.format(new Date());
    const nowInMountainTimeDate = new Date(nowInMountainTimeDateString);
    return nowInMountainTimeDate;
}
exports.nowInMountainTime = nowInMountainTime;
function addMountainTimeOffset(date) {
    const dateInMountainTimeFormattedString = dateTimeFormat.format(date);
    const dateInMountainTimeFormatted = new Date(dateInMountainTimeFormattedString);
    const timeZoneDelta = date.getTime() - dateInMountainTimeFormatted.getTime();
    const dateInMountainTime = new Date(date.getTime() + timeZoneDelta);
    return dateInMountainTime;
}
exports.addMountainTimeOffset = addMountainTimeOffset;
function subtractMountainTimeOffset(date) {
    const dateInMountainTimeFormattedString = dateTimeFormat.format(date);
    const dateInMountainTimeFormatted = new Date(dateInMountainTimeFormattedString);
    const timeZoneDelta = date.getTime() - dateInMountainTimeFormatted.getTime();
    const dateInMountainTime = new Date(date.getTime() - timeZoneDelta);
    return dateInMountainTime;
}
exports.subtractMountainTimeOffset = subtractMountainTimeOffset;
function getNewInvoiceDueDateInMountainTime(forMonth) {
    return new Date(getStartOfNextMonthForDateInMountainTime(addMountainTimeOffset(new Date(forMonth))).getTime() +
        exports.oneDayInMilliseconds * 5);
}
exports.getNewInvoiceDueDateInMountainTime = getNewInvoiceDueDateInMountainTime;
function getBeginningOfTodayInMountainTime() {
    const beginningOfTodayInUTC = new Date(nowInMountainTime().getFullYear(), nowInMountainTime().getMonth(), nowInMountainTime().getDate());
    return addMountainTimeOffset(beginningOfTodayInUTC);
}
exports.getBeginningOfTodayInMountainTime = getBeginningOfTodayInMountainTime;
function getBeginningOfYesterdayInMountainTime() {
    const beginningOfYesterdayInUTC = new Date(nowInMountainTime().getFullYear(), nowInMountainTime().getMonth(), nowInMountainTime().getDate() - 1);
    return addMountainTimeOffset(beginningOfYesterdayInUTC);
}
exports.getBeginningOfYesterdayInMountainTime = getBeginningOfYesterdayInMountainTime;
function millisecondsToHoursMinutesSeconds(milliseconds) {
    const hours = getHoursFromMilliseconds(milliseconds);
    const minutes = getMinutesFromMilliseconds(milliseconds);
    const seconds = getSecondsFromMilliseconds(milliseconds);
    return `${hours === 0 ? '' : `${hours} hr `}${minutes === 0 ? '' : `${minutes} min `}${seconds} sec`;
}
exports.millisecondsToHoursMinutesSeconds = millisecondsToHoursMinutesSeconds;
function millisecondsToHoursMinutesSecondsInSemicolonFormat(milliseconds) {
    const hours = getHoursFromMilliseconds(milliseconds);
    const minutes = getMinutesFromMilliseconds(milliseconds);
    const seconds = getSecondsFromMilliseconds(milliseconds);
    return `${getTimeWithZeros(hours)}:${getTimeWithZeros(minutes)}:${getTimeWithZeros(seconds)}`;
}
exports.millisecondsToHoursMinutesSecondsInSemicolonFormat = millisecondsToHoursMinutesSecondsInSemicolonFormat;
function getHoursFromMilliseconds(milliseconds) {
    return Math.floor(milliseconds / 3600000);
}
function getMinutesFromMilliseconds(milliseconds) {
    return Math.floor((milliseconds % 3600000) / 60000);
}
function getSecondsFromMilliseconds(milliseconds) {
    return Math.floor((milliseconds % 60000) / 1000);
}
function getTimeWithZeros(time) {
    if (time === 0) {
        return '00';
    }
    return time < 10 ? `0${time}` : `${time}`;
}
function checkForMonthFormatCorrect(forMonth) {
    const months = [
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december',
    ];
    const forMonthArray = forMonth.split('-');
    (0, common_1.assertLengthAtLeast)(forMonthArray, 2);
    const month = forMonthArray[0];
    const year = forMonthArray[1];
    return months.includes(month) && !isNaN(parseInt(year)) && year.length === 4;
}
exports.checkForMonthFormatCorrect = checkForMonthFormatCorrect;
const monthPrefixes = {
    0: 'january-',
    1: 'february-',
    2: 'march-',
    3: 'april-',
    4: 'may-',
    5: 'june-',
    6: 'july-',
    7: 'august-',
    8: 'september-',
    9: 'october-',
    10: 'november-',
    11: 'december-',
};
function getStartOfPreviousMonthForDateInMountainTime(date) {
    return addMountainTimeOffset(new Date(date.getFullYear(), date.getMonth() - 1, 1));
}
exports.getStartOfPreviousMonthForDateInMountainTime = getStartOfPreviousMonthForDateInMountainTime;
function getStartOfCurrentMonthForDateInMountainTime(date) {
    return addMountainTimeOffset(new Date(date.getFullYear(), date.getMonth(), 1));
}
exports.getStartOfCurrentMonthForDateInMountainTime = getStartOfCurrentMonthForDateInMountainTime;
function getStartOfNextMonthForDateInMountainTime(date) {
    return addMountainTimeOffset(new Date(date.getFullYear(), date.getMonth() + 1, 1));
}
exports.getStartOfNextMonthForDateInMountainTime = getStartOfNextMonthForDateInMountainTime;
function getStartOfPreviousMonthInMountainTime() {
    return getStartOfPreviousMonthForDateInMountainTime(nowInMountainTime());
}
exports.getStartOfPreviousMonthInMountainTime = getStartOfPreviousMonthInMountainTime;
function getStartOfCurrentMonthInMountainTime() {
    return getStartOfCurrentMonthForDateInMountainTime(nowInMountainTime());
}
exports.getStartOfCurrentMonthInMountainTime = getStartOfCurrentMonthInMountainTime;
function getStartOfNextMonthInMountainTime() {
    return getStartOfNextMonthForDateInMountainTime(nowInMountainTime());
}
exports.getStartOfNextMonthInMountainTime = getStartOfNextMonthInMountainTime;
function getStartOfCurrentMonthForForMonth(forMonth) {
    return getStartOfCurrentMonthForDateInMountainTime(new Date(forMonth));
}
exports.getStartOfCurrentMonthForForMonth = getStartOfCurrentMonthForForMonth;
function getStartOfNextMonthForForMonth(forMonth) {
    return getStartOfNextMonthForDateInMountainTime(new Date(forMonth));
}
exports.getStartOfNextMonthForForMonth = getStartOfNextMonthForForMonth;
function getForMonthForDate(date) {
    const currentMonthIndex = date.getMonth();
    const currentMonthPrefix = monthPrefixes[currentMonthIndex];
    const year = date.getFullYear();
    const forMonth = `${currentMonthPrefix}${year}`;
    return forMonth;
}
exports.getForMonthForDate = getForMonthForDate;
function getForMonthPreviousMonth() {
    const currentMonthIndex = nowInMountainTime().getMonth();
    const previousMonthIndex = currentMonthIndex === 0 ? 11 : currentMonthIndex - 1;
    const previousMonthPrefix = monthPrefixes[previousMonthIndex];
    const currentYear = nowInMountainTime().getFullYear();
    const previousYear = currentMonthIndex === 0 ? currentYear - 1 : currentYear;
    const forMonth = `${previousMonthPrefix}${previousYear}`;
    return forMonth;
}
exports.getForMonthPreviousMonth = getForMonthPreviousMonth;
function getForMonthCurrentMonth() {
    const currentMonthIndex = nowInMountainTime().getMonth();
    const currentMonthPrefix = monthPrefixes[currentMonthIndex];
    const year = nowInMountainTime().getFullYear();
    const forMonth = `${currentMonthPrefix}${year}`;
    return forMonth;
}
exports.getForMonthCurrentMonth = getForMonthCurrentMonth;
function isTimeStampWithinXSecondsAgo(timeStamp, numSecondsAgo) {
    const currentTime = new Date().getTime();
    const timeAtTimeStamp = new Date(timeStamp).getTime();
    const responseTimeDifference = currentTime - timeAtTimeStamp;
    const responseTimeInSeconds = responseTimeDifference / 1000;
    return responseTimeInSeconds < numSecondsAgo;
}
exports.isTimeStampWithinXSecondsAgo = isTimeStampWithinXSecondsAgo;
function getCurrentForMonth() {
    const currentMonth = new Date().toLocaleString('default', { month: 'long' });
    const currentYear = new Date().getFullYear();
    return getForMonth(currentMonth, currentYear);
}
exports.getCurrentForMonth = getCurrentForMonth;
function getForMonth(monthName, year) {
    return `${monthName.toLowerCase()}-${year}`;
}
exports.getForMonth = getForMonth;
