"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.manuallyCloseOutboundQueueItem = void 0;
const graphql_1 = require("../../services/graphql");
const mapped_env_variables_1 = require("../environment/mapped-env-variables");
async function manuallyCloseOutboundQueueItem(outboundQueueItemId) {
    try {
        await (0, graphql_1.gqlUpdateMutation)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint, 'Outbound_queue_items', {
            id: outboundQueueItemId,
            status: 'MANUALLY_CLOSED',
            closed_at: new Date().toISOString(),
        });
    }
    catch (error) {
        throw new Error(`manuallyCloseOutboundQueueItem error: ${error}`);
    }
}
exports.manuallyCloseOutboundQueueItem = manuallyCloseOutboundQueueItem;
