"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGoogleMapsIframe = void 0;
const common_1 = require("@augment-vir/common");
const element_vir_1 = require("@augment-vir/element-vir");
const google_maps_client_iframe_messenger_1 = require("./google-maps-client-iframe-messenger");
const element_vir_2 = require("element-vir");
const interlocking_iframe_messenger_1 = require("interlocking-iframe-messenger");
const JoGoogleMapsIframeWrapper = (0, element_vir_2.defineElementNoInputs)({
    tagName: 'jo-google-maps-iframe-wrapper',
    stateInit: {
        srcDoc: '',
    },
    styles: (0, element_vir_2.css) `
        :host {
            visibility: hidden;
            position: absolute;
            top: 100%;
            left: 100%;
            pointer-events: none;
            z-index: -9999;
            width: 0;
            height: 0;
            overflow: hidden;
        }

        iframe {
            width: inherit;
            height: inherit;
        }
    `,
    renderCallback({ state }) {
        return (0, element_vir_2.html) `
            <iframe srcdoc=${state.srcDoc}></iframe>
        `;
    },
});
function createGoogleMapsIframe(apiKey, apiVersion) {
    const iframeSrcDoc = (0, element_vir_2.html) `
        <script>
            const cachedResults = new Map();
            const googleMapsApiKey = '${apiKey}';
            const googleMapsApiVersion = '${apiVersion}';

            async function loadGoogleMapsPlacesApi() {
                /**
                 * This code is from
                 * https://developers.google.com/maps/documentation/javascript/load-maps-js-api
                 */
                (g => {
                    var h,
                        a,
                        k,
                        p = 'The Google Maps JavaScript API',
                        c = 'google',
                        l = 'importLibrary',
                        q = '__ib__',
                        m = document,
                        b = window;
                    b = b[c] || (b[c] = {});
                    var d = b.maps || (b.maps = {}),
                        r = new Set(),
                        e = new URLSearchParams(),
                        u = () =>
                            h ||
                            (h = new Promise(async (f, n) => {
                                await (a = m.createElement('script'));
                                e.set('libraries', [...r] + '');
                                for (k in g)
                                    e.set(
                                        k.replace(/[A-Z]/g, t => '_' + t[0].toLowerCase()),
                                        g[k],
                                    );
                                e.set('callback', c + '.maps.' + q);
                                a.src = 'https://maps.' + c + 'apis.com/maps/api/js?' + e;
                                d[q] = f;
                                a.onerror = () => (h = n(Error(p + ' could not load.')));
                                a.nonce = m.querySelector('script[nonce]')?.nonce || '';
                                m.head.append(a);
                            }));
                    d[l]
                        ? console.warn(p + ' only loads once. Ignoring:', g)
                        : (d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n)));
                })({
                    key: googleMapsApiKey,
                    v: googleMapsApiVersion,
                    libraries: ['places'],
                });

                /**
                 * Documentation here:
                 * https://developers.google.com/maps/documentation/javascript/place-autocomplete#place_autocomplete_service
                 */
                return globalThis.google.maps.importLibrary('places');
            }

            let services = undefined;

            async function loadGoogleMapsServices() {
                const placesApi = await loadGoogleMapsPlacesApi();

                return {
                    autocompleteService: new placesApi.AutocompleteService(),
                    placesService: new placesApi.PlacesService(
                        /**
                         * This div won't actually be doing anything but the PlacesService requires
                         * it as an input.
                         */
                        document.createElement('div'),
                    ),
                    token: new placesApi.AutocompleteSessionToken(),
                };
            }

            async function getGoogleMapsServices() {
                if (!services) {
                    services = loadGoogleMapsServices();
                }

                return await services;
            }

            async function fetchAutocompleteSuggestions(input) {
                const {autocompleteService, token} = await getGoogleMapsServices();

                const queryObject = {
                    input,
                    sessionToken: token,
                    types: ['address'],
                    componentRestrictions: {
                        country: [
                            'us',
                            'ca',
                        ],
                    },
                };

                const {predictions} = await autocompleteService.getPlacePredictions(queryObject);

                return predictions.map(prediction => {
                    return {
                        placeId: prediction.place_id,
                        structuredFormatting: prediction.structured_formatting,
                    };
                });
            }

            async function fetchPlaceDetails(placeId) {
                const {placesService, token} = await getGoogleMapsServices();
                let outerResolve;
                let outerReject;

                const deferredPromise = new Promise((resolve, reject) => {
                    outerResolve = resolve;
                    outerReject = reject;
                });

                placesService.getDetails(
                    {
                        placeId: placeId,
                        fields: ['address_components'],
                    },
                    (result, status) => {
                        if (status !== globalThis.google.maps.places.PlacesServiceStatus.OK) {
                            outerReject(status);
                        }
                        outerResolve({
                            addressParts: result.address_components,
                        });
                    },
                );

                return deferredPromise;
            }

            globalThis.addEventListener('message', async messageEvent => {
                function sendMessageToParent(data) {
                    const messageForParent = {
                        type: message.type,
                        direction: '${interlocking_iframe_messenger_1.MessageDirectionEnum.FromChild}',
                        data,
                        messageId: message.messageId,
                    };
                    globalThis.parent.postMessage(messageForParent);
                }

                const message = messageEvent.data;
                if (
                    typeof message === 'object' &&
                    message.direction === '${interlocking_iframe_messenger_1.MessageDirectionEnum.FromParent}'
                ) {
                    if (message.type === '${google_maps_client_iframe_messenger_1.GoogleMapsIframeMessageTypeEnum.RequestAutocomplete}') {
                        const queryString = message.data;
                        const cachedResult = cachedResults.get(queryString);
                        const autocompleteResults =
                            cachedResult ?? fetchAutocompleteSuggestions(queryString);
                        if (!cachedResult) {
                            cachedResults.set(queryString, autocompleteResults);
                        }
                        sendMessageToParent(await autocompleteResults);
                    } else if (
                        message.type === '${google_maps_client_iframe_messenger_1.GoogleMapsIframeMessageTypeEnum.RequestPlaceDetails}'
                    ) {
                        const placeId = message.data;
                        const cachedPlaceDetails = cachedResults.get(placeId);
                        const placeDetailsResult = cachedPlaceDetails ?? fetchPlaceDetails(placeId);
                        if (!cachedPlaceDetails) {
                            cachedResults.set(placeId, placeDetailsResult);
                        }
                        sendMessageToParent(await placeDetailsResult);
                    } else {
                        console.warn(
                            'Google Maps iFrame received unexpected message type: ' + message.type,
                        );
                    }
                }
            });
        </script>
    `;
    const iframeWrapper = document.createElement(JoGoogleMapsIframeWrapper.tagName);
    iframeWrapper.instanceState.srcDoc = (0, common_1.collapseWhiteSpace)((0, element_vir_1.convertTemplateToString)(iframeSrcDoc));
    document.body.appendChild(iframeWrapper);
    return iframeWrapper;
}
async function getGoogleMapsIframe(apiKey, apiVersion) {
    const iframeWrapper = document.querySelector(JoGoogleMapsIframeWrapper.tagName) ??
        createGoogleMapsIframe(apiKey, apiVersion);
    function getIframe() {
        return iframeWrapper.shadowRoot?.querySelector('iframe') ?? undefined;
    }
    await (0, common_1.waitForCondition)({
        conditionCallback: () => {
            return !!getIframe();
        },
    });
    const iframeChild = getIframe();
    if (!(iframeChild instanceof HTMLIFrameElement)) {
        throw new Error(`Failed to find Google Maps iFrame element.`);
    }
    return iframeChild;
}
exports.getGoogleMapsIframe = getGoogleMapsIframe;
