"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOutboundQueueItemBasedOnAttemptId = exports.getOutboundQueueItem = exports.getOutboundQueueItemId = exports.closeOutboundQueueItem = void 0;
const graphql_1 = require("../../../../services/graphql");
const manually_close_outbound_queue_item_1 = require("../../../../utilities/outbound-queue-items/manually-close-outbound-queue-item");
const publish_outbound_queue_item_updated_event_1 = require("./publish-outbound-queue-item-updated-event");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function closeOutboundQueueItem(outboundAttemptId) {
    try {
        const outboundQueueItemId = await getOutboundQueueItemId(outboundAttemptId);
        if (outboundQueueItemId)
            await (0, manually_close_outbound_queue_item_1.manuallyCloseOutboundQueueItem)(outboundQueueItemId);
        await (0, publish_outbound_queue_item_updated_event_1.publishOutboundQueueUpdatedMessage)();
    }
    catch (error) {
        throw new Error(`closeOutboundQueueItem error: ${error}`);
    }
}
exports.closeOutboundQueueItem = closeOutboundQueueItem;
async function getOutboundQueueItemId(outboundAttemptId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                query ($outboundAttemptId: Int!) {
                    getOutbound_attempts(id: $outboundAttemptId) {
                        id
                        outbound_queue_item {
                            id
                        }
                    }
                }
            `, {
            outboundAttemptId,
        });
        return gqlResult.data.getOutbound_attempts.outbound_queue_item?.id;
    }
    catch (error) {
        throw new Error(`getOutboundQueueItemId error: ${error}`);
    }
}
exports.getOutboundQueueItemId = getOutboundQueueItemId;
async function getOutboundQueueItem(outboundQueueItemId) {
    try {
        if (!outboundQueueItemId) {
            throw new Error(`getOutboundQueueItem error: outboundQueueItemId is undefined`);
        }
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                query ($outboundQueueItemId: Int!) {
                    getOutbound_queue_items(id: $outboundQueueItemId) {
                        id
                        num_attempts_to_make
                        time_between_attempts
                        status
                        outbound_attempts {
                            id
                        }
                    }
                }
            `, {
            outboundQueueItemId,
        });
        return gqlResult.data.getOutbound_queue_items;
    }
    catch (error) {
        throw new Error(`getOutboundQueueItem error: ${error}`);
    }
}
exports.getOutboundQueueItem = getOutboundQueueItem;
async function getOutboundQueueItemBasedOnAttemptId(outboundAttemptId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($outboundAttemptId: Int!) {
                    getOutbound_attempts(id: $outboundAttemptId) {
                        id
                        outbound_queue_item {
                            id
                            num_attempts_to_make
                            time_between_attempts
                            status
                            outbound_attempts {
                                id
                            }
                        }
                    }
                }
            `, {
            outboundAttemptId,
        });
        const outboundQueueItem = gqlResult.data.getOutbound_attempts?.outbound_queue_item;
        if (!outboundQueueItem) {
            throw new Error(`Error: No outbound queueItem exists for this attempt.`);
        }
        return outboundQueueItem;
    }
    catch (error) {
        throw new Error(`getOutboundQueueItemBasedOnAttemptId error: ${error}`);
    }
}
exports.getOutboundQueueItemBasedOnAttemptId = getOutboundQueueItemBasedOnAttemptId;
