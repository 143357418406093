"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateOutboundAttempt = exports.closeQueueItemsForSelectedCompanyAndCampaignName = exports.closeQueueItem = void 0;
const graphql_1 = require("../../../../services/graphql");
const error_handling_1 = require("../../../../utilities/error-handling");
const publish_outbound_queue_item_updated_event_1 = require("../../jo-call-screen/utilities/publish-outbound-queue-item-updated-event");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function closeQueueItem(queueItemId) {
    try {
        if (!queueItemId) {
            return {
                succeeded: false,
                userMessage: 'Error closeQueueItem',
                developerMessage: 'jo-outbound-table -> mutations -> closeQueueItem queueItemId is not set.',
            };
        }
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($queueItemId: Int!, $closedAt: DateTime) {
                    updateOutbound_queue_items(input: {id: $queueItemId, closed_at: $closedAt}) {
                        id
                    }
                }
            `, {
            queueItemId,
            closedAt: new Date(),
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const resultingID = gqlResult.value.data.updateOutbound_queue_items.id;
        return {
            succeeded: true,
            value: resultingID,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('jo-outbound-table -> mutations -> closeQueueItem', error);
    }
}
exports.closeQueueItem = closeQueueItem;
async function closeQueueItemsForSelectedCompanyAndCampaignName(companyId, campaignName) {
    try {
        if (!companyId) {
            return {
                succeeded: false,
                userMessage: 'Error closeQueueItemsForSelectedCompanyAndCampaignName',
                developerMessage: 'closeQueueItemsForSelectedCompanyAndCampaignName companyOption is not set.',
            };
        }
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($companyId: Int!, $campaignName: String!) {
                    closeAllOutboundQueueItemsForCompany(
                        companyId: $companyId
                        campaignName: $campaignName
                    )
                }
            `, {
            companyId: companyId,
            campaignName: campaignName,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const resultBoolean = gqlResult.value.data.closeAllOutboundQueueItemsForCompany;
        return {
            succeeded: true,
            value: resultBoolean.valueOf(),
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('jo-outbound-table -> mutations -> closeQueueItemsForSelectedCompanyAndCampaignName', error);
    }
}
exports.closeQueueItemsForSelectedCompanyAndCampaignName = closeQueueItemsForSelectedCompanyAndCampaignName;
async function updateOutboundAttempt(params) {
    try {
        if (!params.outboundAttemptId) {
            return {
                succeeded: false,
                userMessage: 'Error Updating Outbound Attempt',
                developerMessage: 'jo-outbound-table -> mutations -> updateOutboundAttempt Id is not set.',
            };
        }
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($input: UpdateOutbound_attemptsInput!) {
                    updateOutbound_attempts(input: $input) {
                        id
                    }
                }
            `, {
            input: {
                id: params.outboundAttemptId,
                action_item: {
                    connect: {
                        id: params.actionItemId,
                    },
                },
                call: {
                    connect: {
                        id: params.callId,
                    },
                },
            },
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const resultingID = gqlResult.value.data.updateOutbound_attempts.id;
        await (0, publish_outbound_queue_item_updated_event_1.publishOutboundQueueUpdatedMessage)();
        return {
            succeeded: true,
            value: resultingID,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('jo-outbound-table -> mutations -> updateOutboundAttempt', error);
    }
}
exports.updateOutboundAttempt = updateOutboundAttempt;
