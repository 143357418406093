"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineCustomElement = void 0;
function defineCustomElement(name, constructor) {
    const existingElement = window.customElements.get(name);
    if (!existingElement) {
        window.customElements.define(name, constructor);
    }
}
exports.defineCustomElement = defineCustomElement;
