"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.googleMapsIframeMessenger = exports.GoogleMapsIframeMessageTypeEnum = void 0;
const interlocking_iframe_messenger_1 = require("interlocking-iframe-messenger");
var GoogleMapsIframeMessageTypeEnum;
(function (GoogleMapsIframeMessageTypeEnum) {
    GoogleMapsIframeMessageTypeEnum["RequestAutocomplete"] = "request-autocomplete";
    GoogleMapsIframeMessageTypeEnum["RequestPlaceDetails"] = "request-place-details";
})(GoogleMapsIframeMessageTypeEnum = exports.GoogleMapsIframeMessageTypeEnum || (exports.GoogleMapsIframeMessageTypeEnum = {}));
exports.googleMapsIframeMessenger = (0, interlocking_iframe_messenger_1.createIframeMessenger)({
    allowedOrigins: [window.location.origin],
});
