"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stylesForjoPopup = void 0;
const lit_html_1 = require("lit-html");
const css_utils_1 = require("../../../utilities/css-utils");
const cssName = (0, css_utils_1.cssPrefixer)('jo-popup');
exports.stylesForjoPopup = (() => {
    return (0, lit_html_1.html) `
        <style>
            .${cssName('main-container')} {
                font-family: sans-serif;
            }

            .${cssName('darken-screen')} {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.65);
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: var(--z-index-3);
            }

            @media (max-width: 768px) {
                .${cssName('darken-screen')} {
                    align-items: flex-start;
                }
            }

            .${cssName('fade-in')} {
                opacity: 0;
                animation-name: jo-popup--fade-in;
                animation-duration: 0.125s;
                animation-fill-mode: forwards;
            }

            .${cssName('window')} {
                width: 35%;
                background: white;
                border-radius: 10px;
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
            }

            @media (max-width: 1280px) {
                .${cssName('window')} {
                    width: 50%;
                }
            }

            @media (max-width: 768px) {
                .${cssName('window')} {
                    margin-top: 18%;
                    width: 75%;
                }
            }

            .${cssName('window-popup')} {
                opacity: 0;
                transform: scale(0.5);
                animation-name: jo-popup--window-popup;
                animation-duration: 0.15s;
                animation-fill-mode: forwards;
            }

            .${cssName('title-bar')} {
                background: var(--jo-primary);
                color: white;
                font-weight: bold;
                padding: 1.2em;
                border-bottom: 1px solid var(--jo-accent-background-light);
                box-sizing: border-box;
                display: flex;
                align-content: center;
                justify-content: space-between;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }

            .${cssName('buttons-row')} {
                background: var(--jo-accent-background-light);
                padding: 0.5rem;
                display: flex;
                justify-content: flex-end;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                gap: 1rem;
            }

            .${cssName('html-container')} {
                box-sizing: border-box;
                width: 100%;
                max-height: 30rem;
                overflow-y: auto;
                padding: 0.5rem;
            }

            @keyframes jo-popup--fade-in {
                to {
                    opacity: 1;
                }
            }

            @keyframes jo-popup--window-popup {
                to {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        </style>
    `;
})();
