"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createGoogleMapsClient = void 0;
const google_maps_client_iframe_messenger_1 = require("./google-maps-client-iframe/google-maps-client-iframe-messenger");
const common_1 = require("@augment-vir/common");
const handle_error_1 = require("../../../logging/error-handling/handle-error");
const google_maps_iframe_1 = require("./google-maps-client-iframe/google-maps-iframe");
const googleMapsApiKey = 'AIzaSyDK-5gYVPfPrjloA-5KiGmSGKdOLHRL4p4';
/**
 * See https://developers.google.com/maps/documentation/javascript/releases for release options.
 *
 * If this is ever updated, make sure to update the scripts in public/google-maps and the
 * "@types/google.maps" dependency in package.json.
 */
const googleMapsApiVersion = '3.52.6';
const googleMapsRequestCheckIntervalMs = 500;
async function createGoogleMapsClient() {
    try {
        const googleMapsIframe = await (0, google_maps_iframe_1.getGoogleMapsIframe)(googleMapsApiKey, googleMapsApiVersion);
        async function makeGoogleMapsIframeRequest(messageDetails) {
            try {
                const result = await google_maps_client_iframe_messenger_1.googleMapsIframeMessenger.sendMessageToChild({
                    /**
                     * This as cast is needed because the GoogleMapsIframeMessageInputs have
                     * conditionals in them but those conditionals are already going to be taken
                     * care of but the input type for this function.
                     */
                    ...messageDetails,
                    iframeElement: googleMapsIframe,
                    // to account for the fact that the iframe will be making network requests
                    intervalMs: googleMapsRequestCheckIntervalMs,
                });
                return result.data;
            }
            catch (caught) {
                const error = (0, common_1.ensureError)(caught);
                error.message = `Failed to make Google Maps client query: ${error.message}`;
                (0, handle_error_1.handleError)(error, { extraData: { messageDetails } });
                return undefined;
            }
        }
        return {
            async requestPlaceAutocomplete(query) {
                return await makeGoogleMapsIframeRequest({
                    message: {
                        type: google_maps_client_iframe_messenger_1.GoogleMapsIframeMessageTypeEnum.RequestAutocomplete,
                        data: query,
                    },
                    verifyChildData: childData => {
                        return (0, common_1.isRuntimeTypeOf)(childData, 'array');
                    },
                });
            },
            async requestPlaceDetails(placeId) {
                return await makeGoogleMapsIframeRequest({
                    message: {
                        type: google_maps_client_iframe_messenger_1.GoogleMapsIframeMessageTypeEnum.RequestPlaceDetails,
                        data: placeId,
                    },
                    verifyChildData: childData => {
                        return ((0, common_1.typedHasProperty)(childData, 'addressParts') &&
                            (0, common_1.isRuntimeTypeOf)(childData.addressParts, 'array'));
                    },
                });
            },
        };
    }
    catch (caught) {
        const error = (0, common_1.ensureError)(caught);
        (0, handle_error_1.handleError)(error);
        return undefined;
    }
}
exports.createGoogleMapsClient = createGoogleMapsClient;
