"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOButton = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../services/utilities");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const element_vir_1 = require("element-vir");
const InitialState = {
    disabled: false,
    href: '',
    icon: '',
    inlineStyle: '',
    materialIconName: '',
    target: '_self',
    text: '',
    type: 'ACTION_BUTTON_1',
    helpText: '',
    helpTextStyles: '',
};
const typesToClasses = {
    ACTION_BUTTON_1: 'jo-global--action-button-1',
    ACTION_BUTTON_2: 'jo-global--action-button-2',
    ACTION_BUTTON_GREEN: 'jo-global--action-button-green',
    ACTION_BUTTON_GREEN_2: 'jo-global--action-button-green-2',
    DELETE_BUTTON_1: 'jo-global--delete-button-1',
    DELETE_BUTTON_2: 'jo-global--delete-button-2',
    SECONDARY_BUTTON_1: 'jo-global--secondary-button-1',
    ICON: 'material-icons jo-global--action-icon',
    ICON_OUTLINED: 'material-icons-outlined jo-global--action-icon',
    LOGIN_BUTTON: 'jo-global--login-button',
    ALERT_BUTTON_ACCEPT: 'jo-global--alert-button jo-global--alert-button-fill',
    ALERT_BUTTON_CANCEL: 'jo-global--alert-button jo-global--alert-button-cancel',
    EMPTY: '',
};
class JOButton extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    set type(type) {
        this.store.type = type;
    }
    buttonClicked(e) {
        if ((0, utilities_1.wasDoubleClicked)(e) || this.store.disabled === true) {
            e.stopPropagation();
        }
    }
    anchorClick() {
        this.querySelector('a')?.click();
    }
    preventKeyPressAfterClick() {
        const activeElement = document.activeElement;
        if (activeElement !== null) {
            activeElement.blur();
        }
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-button--main-container {
                    display: flex;
                    align-items: center;
                    font-family: sans-serif;
                }

                .jo-button--help-text-container {
                    position: relative;
                    display: inline-block;
                }

                .jo-button--help-text {
                    display: none;
                    position: absolute;
                    background-color: #f9f9f9;
                    color: #333;
                    border: 1px solid #ccc;
                    padding: 8px;
                    border-radius: 4px;
                    font-size: 14px;
                    bottom: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    white-space: nowrap;
                    z-index: 1;
                    bottom: 2rem;
                    left: 0;
                }

                .jo-button--help-text-container:hover .jo-button--help-text {
                    display: block;
                }

                .jo-button--disabled {
                    z-index: var(--z-index-1);
                    opacity: 0.4;
                    cursor: not-allowed;
                    pointer-events: none;
                }

                .jo-button--enabled {
                    cursor: pointer;
                }

                .jo-button--no-margin {
                    margin: 0;
                }

                @media screen and (max-width: 1500px) {
                    .jo-button--main-container {
                        font-size: calc(6px + 1vmin);
                    }
                }
            </style>

            <div
                class="jo-button--main-container jo-button--help-text-container"
                @click=${(e) => this.buttonClicked(e)}
            >
                ${(0, element_vir_1.renderIf)(!!state.helpText, (0, lit_html_1.html) `
                        <div class="jo-button--help-text" style="${state.helpTextStyles}">
                            ${state.helpText}
                        </div>
                    `)}
                ${state.href === ''
            ? (0, lit_html_1.html) `
                          <button
                              class="${typesToClasses[state.type]} ${state.disabled
                ? 'jo-button--disabled'
                : 'jo-button--enabled'} jo-button--no-margin"
                              style="${state.inlineStyle}"
                              ?disabled=${state.disabled}
                              @click=${(e) => {
                if ((0, utilities_1.wasDoubleClicked)(e) === true) {
                    return;
                }
                this.preventKeyPressAfterClick();
                (0, utilities_1.raiseCustomEvent)(this, 'joButtonClick', {
                    detail: e,
                });
            }}
                          >
                              <span style="display: flex; align-items: center;">
                                  ${state.icon === '' ? state.text : state.icon}
                                  ${state.materialIconName
                ? (0, lit_html_1.html) `
                                            <span class="material-icons">
                                                ${state.materialIconName}
                                            </span>
                                        `
                : ''}
                              </span>
                          </button>
                      `
            : (0, lit_html_1.html) `
                          <a
                              class="${typesToClasses[state.type]} ${state.disabled
                ? 'jo-button--disabled'
                : 'jo-button--enabled'}"
                              style="${state.inlineStyle}"
                              ?disabled=${state.disabled}
                              @click=${(e) => {
                if (!state.href.startsWith('mailto') &&
                    !state.href.startsWith('http')) {
                    e.preventDefault();
                    (0, utilities_1.navigate)(state.href);
                }
            }}
                              href=${state.href}
                              target=${state.target}
                          >
                              ${state.icon === '' ? state.text : state.icon}
                          </a>
                      `}
            </div>
        `;
    }
}
exports.JOButton = JOButton;
(0, define_custom_element_1.defineCustomElement)('jo-button', JOButton);
