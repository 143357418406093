"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWebClientInterface = void 0;
const common_1 = require("@augment-vir/common");
const mock_vir_1 = require("mock-vir");
const env_1 = require("../../utilities/environment/env");
const create_sentry_client_1 = require("./individual-web-clients/create-sentry-client");
const create_firebase_client_1 = require("./individual-web-clients/create-firebase-client");
const create_google_maps_client_1 = require("./individual-web-clients/google-maps-client/create-google-maps-client");
const clientCreationCallbacks = (0, common_1.wrapNarrowTypeWithTypeCheck)()({
    sentryClient: create_sentry_client_1.createSentryClient,
    firebaseClient: create_firebase_client_1.createFirebaseClient,
    googleMapsClient: create_google_maps_client_1.createGoogleMapsClient,
});
function lazilyCreateClientInterface() {
    // as cast here because we're starting out with nothing
    const clientInterface = {};
    const createdClients = {};
    (0, common_1.getObjectTypedKeys)(clientCreationCallbacks).forEach(clientNameProp => {
        function createClient() {
            const newClient = (0, env_1.isEnv)(env_1.EnvEnum.Testing)
                ? (0, mock_vir_1.createMockVir)()
                : clientCreationCallbacks[clientNameProp]();
            createdClients[clientNameProp] = newClient;
            return newClient;
        }
        Object.defineProperty(clientInterface, clientNameProp, {
            get: () => {
                return createdClients[clientNameProp] ?? createClient();
            },
        });
    });
    return clientInterface;
}
/**
 * Do not ever export this! WebClientInterface should only be accessed through
 * getWebClientInterface.
 *
 * This is marked deprecated so it's clear that it should not be used outside of this file.
 *
 * @deprecated
 */
const currentWebClientInterface = lazilyCreateClientInterface();
function getWebClientInterface() {
    return currentWebClientInterface;
}
exports.getWebClientInterface = getWebClientInterface;
