"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOPopUp = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const css_utils_1 = require("../../../utilities/css-utils");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const styles_1 = require("./styles");
const InitialState = {
    title: '',
    htmlComponents: undefined,
    includeCancelButton: false,
    confirmButtonText: 'Done',
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-popup');
class JOPopUp extends HTMLElement {
    constructor(params) {
        super();
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
        this.store.title = params.title;
        this.store.htmlComponents = params.htmlComponents;
        this.store.includeCancelButton = params.includeCancelButton ?? false;
        this.store.confirmButtonText = params.confirmButtonText ?? 'Done';
    }
    buttonClicked(response) {
        if (response === true) {
            this.dispatchEvent(new CustomEvent('ok'));
        }
        else {
            this.dispatchEvent(new CustomEvent('cancel'));
        }
    }
    render(state) {
        return (0, lit_html_1.html) `
            ${styles_1.stylesForjoPopup}

            <div class="${cssName('main-container')}">
                <div class="${cssName('darken-screen fade-in')}">
                    <div class="${cssName('window window-popup')}">
                        <div
                            class="${cssName('title-bar')}"
                            ?hidden=${this.store.title ? false : true}
                        >
                            <div>${this.store.title}</div>
                        </div>

                        <div class="${cssName('html-container')}">${this.store.htmlComponents}</div>

                        <div class="${cssName('buttons-row')}">
                            <jo-button
                                id="jo-popup-button"
                                .type=${'ALERT_BUTTON_ACCEPT'}
                                .text=${state.confirmButtonText}
                                @joButtonClick=${() => this.buttonClicked(true)}
                            ></jo-button>
                            ${state.includeCancelButton
            ? (0, lit_html_1.html) `
                                      <jo-button
                                          id="jo-popup-button"
                                          .type=${'ALERT_BUTTON_CANCEL'}
                                          .text=${'Cancel'}
                                          @joButtonClick=${() => this.buttonClicked(false)}
                                      ></jo-button>
                                  `
            : ''}
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
exports.JOPopUp = JOPopUp;
(0, define_custom_element_1.defineCustomElement)('jo-popup', JOPopUp);
