"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GqlFetchError = void 0;
function extractMessageForGqlFetchError(responseJson) {
    return (responseJson?.errors ?? []).map((error) => error.message).join(', ');
}
class GqlFetchError extends Error {
    constructor(responseJson, query, variables) {
        super(extractMessageForGqlFetchError(responseJson));
        this.responseJson = responseJson;
        this.query = query;
        this.variables = variables;
        this.name = 'GqlFetchError';
        this.url = window.location.toString();
    }
    toString() {
        return JSON.stringify({
            ...this.responseJson,
            responseJsonErrors: this.responseJson.errors,
            query: this.query,
            variables: this.variables,
            url: this.url,
        }, null, 2);
    }
}
exports.GqlFetchError = GqlFetchError;
