"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSentryClient = void 0;
const Sentry = __importStar(require("@sentry/browser"));
const browser_1 = require("@sentry/browser");
const env_1 = require("../../../utilities/environment/env");
const add_data_to_error_1 = require("../../logging/error-handling/add-data-to-error");
const log_context_1 = require("../../logging/log-context");
const env_category_1 = require("../../../utilities/environment/env-category");
const global_variables_1 = require("../../../utilities/environment/global-variables");
// RELEASE_VERSION is replaced by webpack's build pipeline
globalThis.RELEASE_VERSION = RELEASE_VERSION;
function handleSentrySend(event, hint) {
    const consoleMethod = (0, log_context_1.getConsoleMethodForSeverity)(event.level);
    if ((0, env_category_1.isEnvCategory)(env_1.EnvEnum.Dev)) {
        consoleMethod(`would have sent '${event.level}' event to Sentry:`, hint.originalException, event.extra);
        // prevent Sentry from sending this event
        return null;
    }
    else {
        consoleMethod(`sending '${event.level}' event to Sentry:`, hint.originalException, event.extra);
        return event;
    }
}
function initSentry() {
    /**
     * Normally this init function would only get called once but currently some of our modules are
     * executed multiple times so we must store whether this has been called on the only thing
     * persist between module executions: the global object.
     */
    if ((0, global_variables_1.getGlobalVariable)(global_variables_1.GlobalVariableNameEnum.SentryLoaded)) {
        return;
    }
    // this URL is a public key, it does not need to be a secret
    const sentryDsn = `https://3691b6ac816c43a183b4601f256a9f64@o174851.ingest.sentry.io/4504594716753920`;
    try {
        (0, global_variables_1.setGlobalVariable)(global_variables_1.GlobalVariableNameEnum.SentryLoaded, true);
        Sentry.init({
            beforeSend: handleSentrySend,
            beforeSendTransaction: handleSentrySend,
            defaultIntegrations: false,
            dsn: sentryDsn,
            enabled: true,
            environment: env_1.currentEnv,
            integrations: [
                new browser_1.HttpContext(),
                new browser_1.Dedupe(),
                new browser_1.InboundFilters(),
                new browser_1.FunctionToString(),
                new browser_1.GlobalHandlers(),
            ],
            release: RELEASE_VERSION,
        });
        Sentry.addGlobalEventProcessor((event, hint) => {
            const extraData = (0, add_data_to_error_1.extractExtraErrorData)(hint.originalException);
            if (extraData) {
                const context = (0, log_context_1.createLoggingContext)(extraData);
                Object.assign(event, context);
            }
            return event;
        });
    }
    catch (error) {
        // we don't want Sentry init errors to crash our app so let's just log them
        console.error(error);
    }
}
function createSentryClient() {
    initSentry();
    return Sentry;
}
exports.createSentryClient = createSentryClient;
