"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasPermission = exports.doesUserHaveAuthRole = exports.isJillExecutive = exports.is24_7Jill = exports.isCompanyUser = exports.isJillAtOrAboveAuthRole = exports.jillAuthRolesOrderedByPrivilege = void 0;
exports.jillAuthRolesOrderedByPrivilege = [
    'JILL',
    'JILL_OUTBOUND',
    'JILL_MANAGER',
    'JILL_BUSINESS_DEVELOPMENT',
    'JILL_EXECUTIVE',
];
function isJillAtOrAboveAuthRole(jill, authRole) {
    if (!jill || !authRole || jill === 'NOT_SET')
        return false;
    const positionOfAuthRole = exports.jillAuthRolesOrderedByPrivilege.indexOf(authRole);
    if (positionOfAuthRole === -1)
        return false;
    const positionOfUserAuthRole = exports.jillAuthRolesOrderedByPrivilege.indexOf(jill.auth_role);
    return positionOfUserAuthRole >= positionOfAuthRole;
}
exports.isJillAtOrAboveAuthRole = isJillAtOrAboveAuthRole;
function isCompanyUser(user) {
    if (user === 'NOT_SET')
        return false;
    return user.auth_role === 'COMPANY_EMPLOYEE' || user.auth_role === 'COMPANY_OWNER';
}
exports.isCompanyUser = isCompanyUser;
function is24_7Jill(jill) {
    return jill.auth_role === 'JILL' && jill.is_24_7 === true;
}
exports.is24_7Jill = is24_7Jill;
function isJillExecutive(authenticatedUser) {
    if (authenticatedUser === 'NOT_SET')
        return false;
    return authenticatedUser.auth_role === 'JILL_EXECUTIVE';
}
exports.isJillExecutive = isJillExecutive;
function doesUserHaveAuthRole(user, authRole) {
    if (user === 'NOT_SET')
        return false;
    return user.auth_role === authRole;
}
exports.doesUserHaveAuthRole = doesUserHaveAuthRole;
function hasPermission(user, permissionKey) {
    if (user === 'NOT_SET' || !user.permissions) {
        return false;
    }
    return user.permissions[permissionKey] === true;
}
exports.hasPermission = hasPermission;
