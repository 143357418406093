"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.genericJOFailure = void 0;
function genericJOFailure(functionName, error) {
    return {
        succeeded: false,
        userMessage: 'There was an error',
        developerMessage: `${functionName} error${error ? `: ${error}` : ''}`,
    };
}
exports.genericJOFailure = genericJOFailure;
