"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ensureDataIsDefined = exports.gqlSubscription = exports.gqlRequestResult = exports.gqlUpdateMutation = exports.gqlMutation = exports.gqlRequest = exports.gql = void 0;
const common_1 = require("@augment-vir/common");
const dates_and_times_1 = require("../utilities/dates-and-times/dates-and-times");
const logout_detector_1 = require("../services/logout-detector");
const constants_1 = require("./constants");
const gql_error_1 = require("./gql-error");
const send_log_1 = require("./logging/send-log");
const utilities_1 = require("./utilities");
const mapped_env_variables_1 = require("../utilities/environment/mapped-env-variables");
const add_data_to_error_1 = require("./logging/error-handling/add-data-to-error");
function gql(strings) {
    return strings.join();
}
exports.gql = gql;
function gqlRequest(endpoint) {
    const abortController = new AbortController();
    return {
        abortController,
        async execute(query, variables, config) {
            try {
                return await tryGqlRequestFetch(endpoint, query, variables, abortController);
            }
            catch (error) {
                if (shouldRetryGqlFetchRequest({
                    abortController,
                    query,
                    endpoint,
                    retryOnFailedMutation: config?.retryOnFailedMutation,
                })) {
                    return await retryGqlFetchRequest(endpoint, query, abortController, variables);
                }
                throw error;
            }
        },
    };
}
exports.gqlRequest = gqlRequest;
const graphqlFetchRetryAttemptMax = 10;
async function retryGqlFetchRequest(endpoint, query, abortController, variables) {
    let lastError;
    //we re-attempt this 10 times to compensate for spotty internet connection.
    for (let attemptIndex = 0; attemptIndex < graphqlFetchRetryAttemptMax; attemptIndex++) {
        try {
            return await tryGqlRequestFetch(endpoint, query, variables, abortController);
        }
        catch (caught) {
            lastError = (0, common_1.ensureError)(caught);
            await (0, common_1.wait)(dates_and_times_1.oneSecondInMilliseconds);
            if (attemptIndex > 8) {
                send_log_1.sendLog.info(`Graphql request failed on attempt number ${attemptIndex}`, {
                    extraData: { query, endpoint },
                });
            }
        }
    }
    if (!lastError) {
        /** This will almost certainly not actually ever happen, but we'll cover it anyway. */
        lastError = new Error('no error was caught');
    }
    /** If we make it this far, then the request failed for all retries. */
    lastError.message = [
        'All fetch retries failed',
        lastError.message,
    ].join(': ');
    (0, add_data_to_error_1.addErrorDataAndThrow)(lastError, { extraData: { attemptMax: graphqlFetchRetryAttemptMax } });
}
async function gqlMutation(endpoint, mutation, variables) {
    try {
        return await gqlRequest(endpoint).execute(mutation, variables);
    }
    catch (error) {
        throw new Error(`gqlMutation error: ${error}, ${JSON.stringify({ mutation, variables }, null, 2)}`);
    }
}
exports.gqlMutation = gqlMutation;
async function gqlUpdateMutation(endpoint, gqlEntityName, params) {
    await gqlMutation(endpoint, `
        mutation ($input: Update${gqlEntityName}Input!) {
            update${gqlEntityName} (input: $input) {
                id
            }
        }
    `, {
        input: makeCrudMutationInput(params),
    });
}
exports.gqlUpdateMutation = gqlUpdateMutation;
function makeCrudMutationInput(params) {
    return Object.keys(params).reduce((result, key) => {
        /*@ts-ignore */
        result[key] = params[key];
        return result;
    }, {});
}
function shouldRetryGqlFetchRequest({ abortController, query, endpoint, retryOnFailedMutation, }) {
    const isHeavyContainer = endpoint.includes('heavy');
    const isAborted = abortController.signal.aborted;
    if (isHeavyContainer || isAborted) {
        return false;
    }
    return isQuery(query) || isMutationThatShouldBeRetried(query, retryOnFailedMutation);
}
function isQuery(gqlString) {
    return gqlString.includes('query');
}
function isMutation(gqlString) {
    return gqlString.includes('mutation');
}
function isMutationThatShouldBeRetried(query, retryOnFailedMutation) {
    return isMutation(query) && retryOnFailedMutation !== false;
}
async function tryGqlRequestFetch(endpoint, query, variables, abortController) {
    const responseJSON = await sendGqlFetchRequest(endpoint, query, variables, abortController);
    if (fetchReqReturnedErrors(responseJSON)) {
        throw new gql_error_1.GqlFetchError(responseJSON, query, variables);
    }
    return responseJSON;
}
function fetchReqReturnedErrors(responseJSON) {
    return (responseJSON.errors !== null &&
        responseJSON.errors !== undefined &&
        responseJSON.errors.length > 0);
}
async function sendGqlFetchRequest(endpoint, query, variables, abortController) {
    try {
        (0, logout_detector_1.detectLogout)();
        const bearer = await (0, utilities_1.getBearer)();
        const anonymousAuthJWT = window.localStorage.getItem(constants_1.anonymousAuthJWTName);
        const response = await window.fetch(endpoint, {
            credentials: 'include',
            method: 'POST',
            headers: makeGqlRequestHeaders(bearer, anonymousAuthJWT),
            body: JSON.stringify({
                query,
                variables,
            }),
            signal: abortController.signal,
        });
        return await response.json();
    }
    catch (error) {
        throw new Error(`sendGqlFetchRequest error: ${error.message}`);
    }
}
function makeGqlRequestHeaders(bearer, anonymousAuthJWT) {
    return {
        'Content-Type': 'application/json',
        authorization: `Bearer ${JSON.stringify(bearer)}`,
        ...(anonymousAuthJWT !== null
            ? {
                'x-anonymous-jills-office-auth-jwt': anonymousAuthJWT,
            }
            : {}),
    };
}
// TODO consider making this monadic
// TODO we can return a function that returns a jo result, we can make an assert succeeded that doesn't just throw
function gqlRequestResult(endpoint) {
    const abortController = new AbortController();
    return {
        abortController,
        execute: async (query, variables, config) => {
            const firstAttemptResult = await tryGQLRequestResultFetch(endpoint, query, variables, abortController);
            if (firstAttemptResult.succeeded === true) {
                return firstAttemptResult;
            }
            if (isMutation(query) && config?.retryOnFailedMutation === false) {
                return firstAttemptResult;
            }
            const secondAttemptResult = await tryGQLRequestResultFetch(endpoint, query, variables, abortController);
            return secondAttemptResult;
        },
    };
}
exports.gqlRequestResult = gqlRequestResult;
async function tryGQLRequestResultFetch(endpoint, query, variables, abortController) {
    try {
        const responseJSON = await sendGqlFetchRequest(endpoint, query, variables, abortController);
        // TODO figuring out the errors for sure
        if (fetchReqReturnedErrors(responseJSON)) {
            throw new gql_error_1.GqlFetchError(responseJSON, query, variables);
        }
        return {
            succeeded: true,
            value: responseJSON,
        };
    }
    catch (error) {
        return {
            succeeded: false,
            userMessage: 'An error occurred',
            developerMessage: JSON.stringify({
                error: error.message,
                query,
                variables,
            }),
        };
    }
}
function gqlSubscription(query, callback, variables) {
    return new Promise(resolve => {
        const webSocket = new WebSocket(mapped_env_variables_1.currentMappedEnvVariables.graphqlWebSocketEndpoint, 'graphql-ws');
        webSocket.onerror = () => {
            resolve(undefined);
        };
        webSocket.onopen = async () => {
            await sendConnectionInitMessage(webSocket);
        };
        webSocket.onmessage = event => {
            const eventData = JSON.parse(event.data);
            if (eventData.type === 'connection_ack') {
                sendConnectionStartMessageWithSubscriptionQuery(webSocket, query, variables);
                resolve(webSocket);
            }
            if (eventData.type === 'data') {
                callback(eventData);
            }
        };
    });
}
exports.gqlSubscription = gqlSubscription;
function sendConnectionStartMessageWithSubscriptionQuery(webSocket, query, variables) {
    webSocket.send(JSON.stringify({
        type: 'start',
        payload: {
            query,
            variables,
        },
    }));
}
async function sendConnectionInitMessage(webSocket) {
    const bearer = await (0, utilities_1.getBearer)();
    const message = {
        type: 'connection_init',
        payload: {
            bearer,
        },
    };
    webSocket.send(JSON.stringify(message));
}
function ensureDataIsDefined(gqlResult) {
    if (gqlResult.data === null || gqlResult.data === undefined) {
        throw new Error('data is not defined in GQLResult');
    }
}
exports.ensureDataIsDefined = ensureDataIsDefined;
