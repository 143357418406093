"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertSnakeToTitleCase = void 0;
function convertSnakeToTitleCase(input) {
    return input
        .split('_')
        .map(word => {
        return (word.trim().substring(0, 1).toUpperCase() + word.toLowerCase().trim().substring(1));
    })
        .join(' ')
        .replace(/ {2,}/g, ' ');
}
exports.convertSnakeToTitleCase = convertSnakeToTitleCase;
