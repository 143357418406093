"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGlobalVariable = exports.setGlobalVariable = exports.GlobalVariableNameEnum = void 0;
var GlobalVariableNameEnum;
(function (GlobalVariableNameEnum) {
    GlobalVariableNameEnum["SentryLoaded"] = "SentryLoaded";
})(GlobalVariableNameEnum = exports.GlobalVariableNameEnum || (exports.GlobalVariableNameEnum = {}));
function getGlobalThisWithJillsOfficeGlobals() {
    return globalThis;
}
getGlobalThisWithJillsOfficeGlobals().jillsOfficeGlobals = {};
function setGlobalVariable(globalVarName, data) {
    getGlobalThisWithJillsOfficeGlobals().jillsOfficeGlobals[globalVarName] = data;
}
exports.setGlobalVariable = setGlobalVariable;
function getGlobalVariable(globalVarName) {
    return getGlobalThisWithJillsOfficeGlobals().jillsOfficeGlobals[globalVarName];
}
exports.getGlobalVariable = getGlobalVariable;
