"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleGqlResultFailure = void 0;
const handle_error_1 = require("../../services/logging/error-handling/handle-error");
const utilities_1 = require("../../services/utilities");
function handleGqlResultFailure(gqlResult) {
    (0, utilities_1.joAlert)('Error', gqlResult.userMessage);
    (0, handle_error_1.handleError)(gqlResult.developerMessage);
}
exports.handleGqlResultFailure = handleGqlResultFailure;
