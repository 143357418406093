"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalStore = exports.InitialGlobalState = void 0;
const dequal_1 = require("dequal");
const reduxular_1 = require("reduxular");
const path_change_pub_sub_1 = require("../utilities/routes/path-change-pub-sub");
const are_objects_equal_minus_form_errors_1 = require("./are-objects-equal-minus-form-errors");
const constants_1 = require("./constants");
exports.InitialGlobalState = {
    authenticatedUser: 'NOT_SET',
    availabilitySubscriptionCreated: false,
    currentCallInformation: {
        callAnswered: false,
        canInitiateNewTransfer: false,
        callIncoming: false,
        direction: 'NOT_SET',
        incomingCallType: 'NOT_SET',
        incomingConnection: 'NOT_SET',
        jillCallSid: 'NOT_SET',
        returnPath: '/',
        userCallSid: 'NOT_SET',
        userCallStatus: 'NOT_SET',
    },
    inputValues: {
        companyDetails: {
            inputValues: {
                companyBillingInfoInputValues: 'NOT_SET',
                companyContactInfoInputValues: 'NOT_SET',
                companyJillnInfoInputValues: 'NOT_SET',
            },
            inputValuesSnapshot: {
                companyBillingInfoInputValues: 'NOT_SET',
                companyContactInfoInputValues: 'NOT_SET',
                companyJillnInfoInputValues: 'NOT_SET',
            },
            inputValuesUnsavedChanges: false,
        },
        callInstructionsGeneralSetup: {
            inputValues: {
                callScreenInfoInputValues: 'NOT_SET',
                generalInformationInputValues: 'NOT_SET',
                serviceAreaInputValues: 'NOT_SET',
            },
            inputValuesSnapshot: {
                callScreenInfoInputValues: 'NOT_SET',
                generalInformationInputValues: 'NOT_SET',
                serviceAreaInputValues: 'NOT_SET',
            },
            inputValuesUnsavedChanges: false,
        },
        contactSave: {
            inputValues: 'NOT_SET',
            inputValuesSnapshot: 'NOT_SET',
            inputValuesUnsavedChanges: false,
        },
        servicesPricing: {
            inputValues: {
                customFieldsInputValues: 'NOT_SET',
                servicesInputValues: 'NOT_SET',
                pricingInputValues: 'NOT_SET',
            },
            inputValuesSnapshot: {
                customFieldsInputValues: 'NOT_SET',
                servicesInputValues: 'NOT_SET',
                pricingInputValues: 'NOT_SET',
            },
            inputValuesUnsavedChanges: false,
        },
    },
    isMobileDevice: window.matchMedia(constants_1.MOBILE_DEVICE_MEDIA_QUERY).matches,
    loading: true,
    mobileTopBarTitle: '',
    personalAdminModeSubscriptionCreated: false,
    route: {
        actionItemId: 'NOT_SET',
        blockingNavigation: false,
        callId: 'NOT_SET',
        callInstructionId: 'NOT_SET',
        callScreenMode: 'NOT_SET',
        companyId: 'NOT_SET',
        contactId: 'NOT_SET',
        customerActionItemMode: 'EDIT',
        employeeId: 'NOT_SET',
        forMonth: 'NOT_SET',
        invoiceId: 'NOT_SET',
        jillId: 'NOT_SET',
        minutesPackageId: 'NOT_SET',
        minutesPackageMode: 'EDIT',
        outboundAttemptId: 'NOT_SET',
        pathname: window.location.pathname,
        previousFullPath: '/',
        search: window.location.search,
        selectedCompanyNavBarMainOption: 'NOT_SET',
        selectedCompanyNavBarSecondaryOption: 'NOT_SET',
        selectedCustomerSideBarMainOption: 'NOT_SET',
        teamId: 'NOT_SET',
        userId: 'NOT_SET',
        userMode: 'EDIT',
    },
    showSidebar: false,
    subscriptions: {
        outgoingCallInitiatedSubscription: 'NOT_SET',
        outgoingUserCallStatusSubscription: 'NOT_SET',
    },
    twilioDevice: 'NOT_SET',
    workStatusChangedSubscriptionCreated: false,
    incomingTwilioConnection: undefined,
};
exports.GlobalStore = (0, reduxular_1.createObjectStore)(exports.InitialGlobalState, () => { }, {}, (state, action) => {
    const newState = rootReducer(state, action);
    return {
        ...newState,
        isMobileDevice: areWeInMobileView(),
    };
});
window.addEventListener('resize', () => {
    if (exports.GlobalStore.isMobileDevice === false && areWeInMobileView() === true) {
        exports.GlobalStore.isMobileDevice = true;
    }
    if (exports.GlobalStore.isMobileDevice === true && areWeInMobileView() === false) {
        exports.GlobalStore.isMobileDevice = false;
    }
});
function areWeInMobileView() {
    return window.matchMedia(constants_1.MOBILE_DEVICE_MEDIA_QUERY).matches;
}
function rootReducer(state, action) {
    if (action.type === 'CALL_INSTRUCTIONS_GENERAL_SETUP_INPUT_VALUES_CHANGED') {
        const snapshotNotSet = state.inputValues.callInstructionsGeneralSetup.inputValuesSnapshot
            .callScreenInfoInputValues === 'NOT_SET' &&
            state.inputValues.callInstructionsGeneralSetup.inputValuesSnapshot
                .generalInformationInputValues === 'NOT_SET' &&
            state.inputValues.callInstructionsGeneralSetup.inputValuesSnapshot
                .serviceAreaInputValues === 'NOT_SET';
        const snapshot = snapshotNotSet === true
            ? action.callInstructionsGeneralSetupInputValues
            : state.inputValues.callInstructionsGeneralSetup.inputValuesSnapshot;
        return {
            ...state,
            inputValues: {
                ...state.inputValues,
                callInstructionsGeneralSetup: {
                    ...state.inputValues.callInstructionsGeneralSetup,
                    inputValues: action.callInstructionsGeneralSetupInputValues,
                    inputValuesSnapshot: snapshot,
                    inputValuesUnsavedChanges: (0, dequal_1.dequal)(action.callInstructionsGeneralSetupInputValues, snapshot) === false,
                },
            },
        };
    }
    if (action.type === 'CALL_INSTRUCTIONS_GENERAL_SETUP_INPUT_VALUES_RESET_SNAPSHOT') {
        return {
            ...state,
            inputValues: {
                ...state.inputValues,
                callInstructionsGeneralSetup: exports.InitialGlobalState.inputValues.callInstructionsGeneralSetup,
            },
        };
    }
    if (action.type === 'CHANGE_ROUTE') {
        path_change_pub_sub_1.pathChangePubSub.publish(action.pathname, action.previousFullPath);
        return getUpdatedRouteState(state, action);
    }
    if (action.type === 'COMPANY_DETAILS_INPUT_VALUES_CHANGED') {
        const snapshotNotSet = state.inputValues.companyDetails.inputValuesSnapshot.companyBillingInfoInputValues ===
            'NOT_SET' &&
            state.inputValues.companyDetails.inputValuesSnapshot.companyContactInfoInputValues ===
                'NOT_SET' &&
            state.inputValues.companyDetails.inputValuesSnapshot.companyJillnInfoInputValues ===
                'NOT_SET';
        const snapshot = snapshotNotSet === true
            ? action.companyDetailsInputValues
            : state.inputValues.companyDetails.inputValuesSnapshot;
        const areThereUnsavedChanges = !(0, are_objects_equal_minus_form_errors_1.areObjectsEqualMinusFormErrors)(action.companyDetailsInputValues, snapshot);
        return {
            ...state,
            inputValues: {
                ...state.inputValues,
                companyDetails: {
                    ...state.inputValues.companyDetails,
                    inputValues: action.companyDetailsInputValues,
                    inputValuesSnapshot: snapshot,
                    inputValuesUnsavedChanges: areThereUnsavedChanges,
                },
            },
        };
    }
    if (action.type === 'COMPANY_DETAILS_INPUT_VALUES_RESET_SNAPSHOT') {
        return {
            ...state,
            inputValues: {
                ...state.inputValues,
                companyDetails: exports.InitialGlobalState.inputValues.companyDetails,
            },
        };
    }
    if (action.type === 'CONTACT_SAVE_INPUT_VALUES_CHANGED') {
        const snapshotNotSet = state.inputValues.contactSave.inputValuesSnapshot === 'NOT_SET';
        const snapshot = snapshotNotSet === true
            ? action.contactSaveInputValues
            : state.inputValues.contactSave.inputValuesSnapshot;
        return {
            ...state,
            inputValues: {
                ...state.inputValues,
                contactSave: {
                    ...state.inputValues.contactSave,
                    inputValues: action.contactSaveInputValues,
                    inputValuesSnapshot: snapshot,
                    inputValuesUnsavedChanges: (0, dequal_1.dequal)(action.contactSaveInputValues, snapshot) === false,
                },
            },
        };
    }
    if (action.type === 'CONTACT_SAVE_INPUT_VALUES_RESET_SNAPSHOT') {
        return {
            ...state,
            inputValues: {
                ...state.inputValues,
                contactSave: exports.InitialGlobalState.inputValues.contactSave,
            },
        };
    }
    if (action.type === 'SERVICES_PRICING_INPUT_VALUES_CHANGED') {
        const snapshotNotSet = state.inputValues.servicesPricing.inputValuesSnapshot.customFieldsInputValues ===
            'NOT_SET' &&
            state.inputValues.servicesPricing.inputValuesSnapshot.pricingInputValues ===
                'NOT_SET' &&
            state.inputValues.servicesPricing.inputValuesSnapshot.servicesInputValues === 'NOT_SET';
        const snapshot = snapshotNotSet === true
            ? action.servicesPricingInputValues
            : state.inputValues.servicesPricing.inputValuesSnapshot;
        return {
            ...state,
            inputValues: {
                ...state.inputValues,
                servicesPricing: {
                    ...state.inputValues.servicesPricing,
                    inputValues: action.servicesPricingInputValues,
                    inputValuesSnapshot: snapshot,
                    inputValuesUnsavedChanges: (0, dequal_1.dequal)(action.servicesPricingInputValues, snapshot) === false,
                },
            },
        };
    }
    if (action.type === 'SERVICES_PRICING_INPUT_VALUES_RESET_SNAPSHOT') {
        return {
            ...state,
            inputValues: {
                ...state.inputValues,
                servicesPricing: exports.InitialGlobalState.inputValues.servicesPricing,
            },
        };
    }
    if (action.type === 'RESET_CALL_STATE') {
        return {
            ...state,
            currentCallInformation: exports.InitialGlobalState.currentCallInformation,
        };
    }
    return state;
}
function getUpdatedRouteState(state, action) {
    const actionItemId = getIdFromUrl('actionItemId', action.search);
    const callId = getIdFromUrl('callId', action.search);
    const callInstructionId = getIdFromUrl('callInstructionId', action.search);
    const callScreenModeString = new URLSearchParams(action.search).get('callScreenMode');
    const callScreenMode = callScreenModeString === null ? 'NOT_SET' : callScreenModeString;
    const customerActionItemModeString = new URLSearchParams(action.search).get('customerActionItemMode');
    const customerActionItemMode = customerActionItemModeString === null
        ? 'NOT_SET'
        : customerActionItemModeString;
    const companyId = getIdFromUrl('companyId', action.search);
    const contactId = getIdFromUrl('contactId', action.search);
    const employeeId = getIdFromUrl('employeeId', action.search);
    const invoiceId = getIdFromUrl('invoiceId', action.search);
    const jillId = getIdFromUrl('jillId', action.search);
    const minutesPackageId = getIdFromUrl('minutesPackageId', action.search);
    const minutesPackageMode = action.pathname.includes('/minutes-package/create')
        ? 'CREATE'
        : 'EDIT';
    const outboundAttemptId = getIdFromUrl('outboundAttemptId', action.search);
    const selectedCompanyNavBarMainOption = getSelectedCompanyNavBarMainOption(action.pathname);
    const selectedCompanyNavBarSecondaryOption = getSelectedCompanyNavBarSecondaryOption(action.pathname);
    const forMonth = getStringFieldFromUrl('forMonth', action.search);
    const teamId = getIdFromUrl('teamId', action.search);
    const userId = getIdFromUrl('userId', action.search);
    const selectedCustomerSideBarMainOption = getCustomerSideBarMainOption(action.pathname);
    const userMode = action.pathname.includes('/user/create')
        ? 'CREATE'
        : 'EDIT';
    return {
        ...state,
        mobileTopBarTitle: constants_1.MOBILE_TOP_BAR_TITLES[action.pathname] || '',
        route: {
            ...state.route,
            actionItemId,
            callId,
            callInstructionId,
            callScreenMode,
            companyId,
            contactId,
            customerActionItemMode,
            employeeId,
            forMonth,
            jillId,
            invoiceId,
            minutesPackageId,
            minutesPackageMode,
            outboundAttemptId,
            pathname: action.pathname,
            previousFullPath: action.previousFullPath ?? '/',
            search: action.search,
            selectedCompanyNavBarMainOption,
            selectedCompanyNavBarSecondaryOption,
            selectedCustomerSideBarMainOption,
            teamId,
            userId,
            userMode,
        },
    };
}
function getIdFromUrl(urlIdName, queryString) {
    const idString = new URLSearchParams(queryString).get(urlIdName);
    if (idString === null || idString === 'NOT_SET') {
        return 'NOT_SET';
    }
    return parseInt(idString);
}
function getStringFieldFromUrl(urlStringFieldName, queryString) {
    const stringField = new URLSearchParams(queryString).get(urlStringFieldName);
    return stringField === null ? 'NOT_SET' : stringField;
}
function getNumberFieldFromUrl(urlNumberFieldName, queryString) {
    const numberFieldString = new URLSearchParams(queryString).get(urlNumberFieldName);
    if (numberFieldString === null || numberFieldString === 'NOT_SET') {
        return 'NOT_SET';
    }
    return parseInt(numberFieldString);
}
function getSelectedCompanyNavBarMainOption(routePath) {
    if (routePath === '/company/company-details') {
        return 'DETAILS';
    }
    // TODO we probably want to be more explicit here, perhaps a startsWith
    // TODO for example, routePath.startsWith('/company/employees')
    // TODO we might want to change the paths slightly to follow this exact convention, /company/employees/employee
    if (routePath.includes('/company/employee')) {
        return 'EMPLOYEES';
    }
    if (routePath.includes('/company/action-item')) {
        return 'ACTION_ITEMS';
    }
    if (routePath.includes('/company/call-instructions')) {
        return 'CALL_INSTRUCTIONS';
    }
    if (routePath.includes('/company/crm') || routePath.includes('/company/contact')) {
        return 'CONTACTS';
    }
    if (routePath.startsWith('/company/billing')) {
        return 'BILLING';
    }
    if (routePath.startsWith('/company/voicemails')) {
        return 'VOICEMAILS';
    }
    if (routePath.startsWith('/company/flagged-action-items')) {
        return 'FLAGGED_ACTION_ITEMS';
    }
    if (routePath.startsWith('/company/account-help')) {
        return 'ACCOUNT_HELP_TICKETS';
    }
    if (routePath.startsWith('/company/custom-hours')) {
        return 'CUSTOM_HOURS';
    }
    if (routePath.startsWith('/company/user-activity-report')) {
        return 'USER_ACTIVITY';
    }
    if (routePath.startsWith('/company/crew-cal')) {
        return 'CREW_CAL';
    }
    // TODO add more as more components get added
    return 'NOT_SET';
}
function getSelectedCompanyNavBarSecondaryOption(pathname) {
    if (pathname === '/company/call-instructions/general-setup') {
        return 'GENERAL_SETUP';
    }
    if (pathname === '/company/call-instructions/call-instruction-pages') {
        return 'CALL_INSTRUCTION_PAGES';
    }
    if (pathname === '/company/call-instructions/services-pricing') {
        return 'SERVICES_PRICING';
    }
    if (pathname === '/company/billing/logs') {
        return 'LOGS';
    }
    if (pathname === '/company/billing/invoice') {
        return 'INVOICE';
    }
    if (pathname === '/company/call-instructions/call-section-notes') {
        return 'CALL_SECTION_NOTES';
    }
    if (pathname === '/company/call-instructions/chat-widget-script-generator') {
        return 'CHAT_WIDGET';
    }
    if (pathname === '/company/call-instructions/transfers') {
        return 'TRANSFERS';
    }
    if (pathname === '/company/crew-cal/calendars') {
        return 'COMPANY_CALENDARS';
    }
    if (pathname === '/company/crew-cal/crew-cal-management') {
        return 'CREW_CAL_MANAGEMENT';
    }
    if (pathname === '/company/call-instructions/quick-notes') {
        return 'QUICK_NOTES';
    }
    return 'NOT_SET';
}
function getCustomerSideBarMainOption(routePath) {
    if (routePath === '/') {
        return 'DASHBOARD';
    }
    // TODO we probably want to be more explicit here, perhaps a startsWith
    // TODO for example, routePath.startsWith('/company/employees')
    // TODO we might want to change the paths slightly to follow this exact convention, /company/employees/employee
    if (routePath.includes('/customer/action-items')) {
        return 'ACTION_ITEMS';
    }
    if (routePath.includes('/customer/contact-list')) {
        return 'CONTACTS';
    }
    if (routePath.includes('/customer/voicemail')) {
        return 'VOICEMAIL';
    }
    if (routePath.includes('/customer/outbound')) {
        return 'OUTBOUND';
    }
    if (routePath.includes('customer/company-profile')) {
        return 'COMPANY_PROFILE';
    }
    if (routePath.includes('/customer/users')) {
        return 'USERS';
    }
    if (routePath.includes('/customer/billing')) {
        return 'BILLING';
    }
    if (routePath.includes('/customer/invoice')) {
        return 'INVOICE';
    }
    if (routePath.includes('/customer/call-log')) {
        return 'LOGS';
    }
    if (routePath.includes('/customer/partner-discounts')) {
        return 'PARTNER_DISCOUNTS';
    }
    if (routePath.includes('/customer/training')) {
        return 'TRAINING';
    }
    if (routePath.includes('/customer/contact-us')) {
        return 'CONTACT_US';
    }
    return 'NOT_SET';
}
