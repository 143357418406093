"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pathChangePubSub = void 0;
exports.pathChangePubSub = makePathChangePubSub();
function makePathChangePubSub() {
    const subscribers = new Set();
    return {
        subscribe(callback) {
            subscribers.add(callback);
        },
        publish(newPath, previousPath) {
            subscribers.forEach(subscriberFn => subscriberFn(newPath, previousPath));
        },
        unsubscribe(callback) {
            subscribers.delete(callback);
        },
    };
}
