"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateOutboundQueueItemStatus = void 0;
const graphql_1 = require("../../services/graphql");
const should_mark_outbound_queue_item_as_completed_1 = require("./should-mark-outbound-queue-item-as-completed");
const mapped_env_variables_1 = require("../environment/mapped-env-variables");
async function updateOutboundQueueItemStatus(outboundQueueItem) {
    try {
        const outboundQueueItemId = outboundQueueItem.id;
        if ((0, should_mark_outbound_queue_item_as_completed_1.shouldMarkOutboundQueueItemAsCompleted)(outboundQueueItem)) {
            await (0, graphql_1.gqlUpdateMutation)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint, 'Outbound_queue_items', {
                id: outboundQueueItemId,
                status: 'COMPLETED',
                closed_at: new Date().toISOString(),
            });
        }
        else {
            await (0, graphql_1.gqlUpdateMutation)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint, 'Outbound_queue_items', {
                id: outboundQueueItemId,
                status: 'IN_PROGRESS',
            });
        }
    }
    catch (error) {
        throw new Error(`markOutboundQueueItemAsCompletedIfNecessary error: ${error}`);
    }
}
exports.updateOutboundQueueItemStatus = updateOutboundQueueItemStatus;
