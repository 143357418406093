"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ringtoneCategories = exports.ringtones = void 0;
const common_1 = require("@augment-vir/common");
exports.ringtones = {
    default: {
        Default: 'Default',
        Classic: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/ringing_old_phone.mp3',
        Jills_Ring: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/jills-ring.mp3',
        Remix_1: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/my_ringtone.mp3',
        Ibiza: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/ibiza.mp3',
        Hard_Work: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/hard_work_work.mp3',
        Beats: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/beats.mp3',
        Dubstep_1: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/dubstep.mp3',
        Dubstep_2: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/dj_remix_bass.mp3',
    },
    country: {
        Its_Your_Love: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/it_s_your_love.mp3',
        Heart_Like_A_Truck: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/heart_like_a_truck.mp3',
        Gods_Country: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/gods_country.mp3',
        Tennessee_Whiskey: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/chris_tennessee_whiske.mp3',
        Everything: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/everything.mp3',
        How_Country_Feels: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/how_country_feels.mp3',
    },
    other: {
        Bohemian_Rhapsody: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/01+Bohemian+Rhapsody.mp3',
        Sexy_And_I_Know_It: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/04+Sexy+and+I+Know+It.mp3',
        This_Is_How_We_Do: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/Katy+Perry+-+This+Is+How+We+Do.mp3',
        Up_Town_Funk: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/up_town_funk.mp3',
        Toosii_Favorite: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/toosii_favorite_song.mp3',
        Tequila: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/tequila.mp3',
        Sugar_Boom_Boom: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/suga_boom_boom.mp3',
        Funky_Town: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/shrek.mp3',
        Shaggy_Angel: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/shaggy_angel.mp3',
        Pretty_Girls_Walk: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/pretty_girls_walk.mp3',
        Party_Rock: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/party_rock.mp3',
        Mr_Blue_Sky: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/mr_blue_sky.mp3',
        Mocking_Bird: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/mockingbird.mp3',
        Gangnam_Style_Remix: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/gangnam_style.mp3',
        Come_Get_Your_Love: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/come_get_your_love.mp3',
        Cats_In_The_Cradle: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/cats_in_the_cradle.mp3',
        Wanted_Dead_Or_Alive: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/bon_jovi_wanted.mp3',
        Bad_To_The_Bone: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/bad_to_the_bone.mp3',
    },
    playful: {
        Super_Mario: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/super_mario.mp3',
        Scooby_Doo: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/scooby_doo_title.mp3',
        Pirates_Of_Carribean: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/pirates_of_carribean.mp3',
        Looney_Toons: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/looney_toons.mp3',
        What_Does_The_Fox_Say: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/fox_ringtone.mp3',
        Diggin_Up_Holes: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/diggin_up_holes.mp3',
        Banana: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/despicable_me_2_song.mp3',
        Everything_Is_Awesome: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/lego_movie_eia.mp3',
    },
    relaxing: {
        Piano_Kara: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/piano_by_kara.mp3',
        Night_Street: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/night-street.mp3',
        Sweet_Piano: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/cool_sweet_piano.mp3',
        Come_Come_Ye_Saints: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/come_come_ye_saints.mp3',
        Beat_Of_Nature: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/beat-of-nature.mp3',
        Purple_Planet: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/Purple+Planet+Music+-+Hope+-+Point+of+Departure+(2_06)+120bpm.mp3',
        Piano_Basics: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/piano.mp3',
    },
    holiday: {
        This_Is_Halloween: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/this_is_halloween.mp3',
        Halloween_Theme: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/halloween_theme.mp3',
        Monster_Mash: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/monster_mash.mp3',
        Zombie_Apocalypse: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/halloween_remix_song.mp3',
        Last_Christmas: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/last_christmas.mp3',
        Grandma_Got_Ran_Over: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/grandma_got_run_over.mp3',
        Carol_Of_The_Bells: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/carol_of_the_bells.mp3',
        Friday: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/its_friday_yay.mp3',
        Happy_Birthday: 'https://ringtones-for-jills.s3.us-west-2.amazonaws.com/happy_birthday.mp3',
    },
};
exports.ringtoneCategories = (0, common_1.mapObjectValues)(exports.ringtones, (categoryName) => categoryName);
