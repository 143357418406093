"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createLoggingContext = exports.getConsoleMethodForSeverity = exports.LogSeverityEnum = void 0;
const common_1 = require("@augment-vir/common");
var LogSeverityEnum;
(function (LogSeverityEnum) {
    LogSeverityEnum["Warning"] = "warning";
    LogSeverityEnum["Info"] = "info";
    LogSeverityEnum["Debug"] = "debug";
    LogSeverityEnum["Fatal"] = "fatal";
    LogSeverityEnum["Error"] = "error";
})(LogSeverityEnum = exports.LogSeverityEnum || (exports.LogSeverityEnum = {}));
const consoleLogMethodsPerSeverity = {
    [LogSeverityEnum.Warning]: console.warn,
    [LogSeverityEnum.Info]: console.info,
    [LogSeverityEnum.Debug]: console.debug,
    [LogSeverityEnum.Fatal]: console.error,
    [LogSeverityEnum.Error]: console.error,
};
function getConsoleMethodForSeverity(severity) {
    if (!(0, common_1.isEnumValue)(severity, LogSeverityEnum)) {
        // default to the .info console method
        return consoleLogMethodsPerSeverity[LogSeverityEnum.Info];
    }
    return consoleLogMethodsPerSeverity[severity];
}
exports.getConsoleMethodForSeverity = getConsoleMethodForSeverity;
function createLoggingContext(logDetails) {
    if (!logDetails) {
        return undefined;
    }
    return {
        extra: logDetails.extraData ?? {},
        level: logDetails.severity,
    };
}
exports.createLoggingContext = createLoggingContext;
