"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Twilio = void 0;
const utilities_1 = require("./utilities");
exports.Twilio = createTwilioObject();
function createTwilioObject() {
    try {
        return {
            Connection: window.Twilio.Connection,
            Device: window.Twilio.Device,
        };
    }
    catch (error) {
        (0, utilities_1.handleError)(error);
    }
}
