"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorWithData = exports.addErrorDataAndThrow = exports.extractExtraErrorData = void 0;
const common_1 = require("@augment-vir/common");
const handle_error_1 = require("./handle-error");
const extraErrorDataSymbol = Symbol('extra-error-data');
function extractExtraErrorData(error) {
    if ((0, common_1.typedHasProperty)(error, extraErrorDataSymbol)) {
        return error[extraErrorDataSymbol];
    }
    else {
        return undefined;
    }
}
exports.extractExtraErrorData = extractExtraErrorData;
/**
 * Adds extra data to an error without modifying the error's message or stack trace (or any of its
 * other properties), then throws the error so it can continue to propagate.
 */
function addErrorDataAndThrow(originalError, extraData) {
    const error = (0, common_1.ensureError)(originalError);
    error[extraErrorDataSymbol] = (0, handle_error_1.convertErrorDetailsToLogDetails)(extraData);
    throw error;
}
exports.addErrorDataAndThrow = addErrorDataAndThrow;
/**
 * Used to construct an error with data attached to it in the same way that addErrorDataAndThrow
 * attaches data.
 *
 * The following examples are equivalent:
 *
 * @example
 *     throw new ErrorWithData('my error', {extraData: 'stuff'});
 *
 * @example
 *     const myError = new Error('my error');
 *     addErrorDataAndThrow(myError, {extraData: 'stuff'});
 */
class ErrorWithData extends Error {
    constructor(message, extraData) {
        super(message);
        this[extraErrorDataSymbol] = (0, handle_error_1.convertErrorDetailsToLogDetails)(extraData);
    }
}
exports.ErrorWithData = ErrorWithData;
